
import "../../public/css/rcu.css";
import Recipe from "../models/Recipe";
import { Menu } from "../Types";
import { Options, Vue } from 'vue-class-component';

export default class MenuBuilder extends Vue {
 //props


  //data variables

  //methods

  //computed

  //lifecycle


}

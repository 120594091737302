<template>
   <form onsubmit="event.preventDefault()" class="searchForm" action="/recipe/admin" method="get">
      <div class="recipelist">
         <div class="heading">
            <div class="pull-right input-group" style="width: 200px; padding: 10px 5px 0 0; margin-bottom: 0px;">
               <input @change="runFilters()" v-model="searchedRecipeTerm" id="Recipe_displayName" type="search" name="Recipe[name]" placeholder="Search Recipes" class="form-control">
               <span class="input-group-btn"><button type="submit" class="btn"><i class="fa fa-search"></i></button></span>
            </div>
            <h2 class="title">Recipe Library</h2>
            <div class="top">
               <!-- <input type="checkbox" class="pull-left selectDeselect pull-left">
               <button class="btn pull-left btn-danger" onclick="window.General.recipeBulkDelete();"><i class="fa fa-trash-o"></i> Delete</button> -->

               <button data-toggle="modal" data-target="#importRecipeModal" class="btn pull-right btn-ltgreen"><i class="fa fa-cloud-download"></i> Base Recipes</button>
               <button class="btn pull-right btn-primary disabled hidden"><i class="fa fa-cloud"></i> Test Flight</button>
               <a @click="$emit('recipeCreateSelected')" href="#" class="btn pull-right btn-ltgreen"><i class="fa fa-plus"></i> New Recipe</a>
               <button class="btn pull-right btn-ltgreen hidden"><i class="fa fa-share"></i> Share</button>
            </div>
            <div class="bottom">
               <div class="pull-right" style="display: flex;">
                  <select v-model="recipesPerPage" class="form-control pull-right" style="">
                     <option value="50">50</option>
                     <option value="100">100</option>
                     <option value="200">200</option>
                  </select>
                  <a @click="sortByName(0)" class="btn btn-default"><i class="fa fa-sort-alpha-asc"></i></a>
                  <a @click="sortByDate(0)" class="btn btn-primary"><i class="fa fa-long-arrow-down"></i> Date</a>
               </div>
               Refine by:
               <span style="margin: 0 10px;">
                  <select @change="runFilters()" v-model="filteredAccount" id="Recipe_account" class="searchAttr form-control" name="Recipe[account]">
                            <option value="0">All Accounts</option>
                            <option value="">-</option>
                            <option :value="currentAccount">My Account</option>
                            <option v-for="account in sharedAccounts" :value="account.id">{{account.name}}</option>
                         </select>
               </span>
               <span>
                  <select @change="runFilters()" v-model="filteredType" id="Recipe_type" name="Recipe[type]" class="searchAttr type form-control">
                            <option value="0">Medium</option>
                            <option value="">-</option>
                            <option value="1">Tea</option>
                            <option value="2">Coffee</option>
                            <option value="3">Spirits</option>
                        </select>
               </span>
               <span>

               </span>
               <br>

               <div class="tagcloud">
               </div>
            </div>
         </div>
         <div id="recipemanager_listview" class="list-view">
            <div class="summary">Displaying {{filteredRecipes.length == 0 ? '0' : (currentPage*recipesPerPage) - recipesPerPage+1}} - {{filteredRecipes.length < recipesPerPage ? filteredRecipes.length : recipesPerPage * currentPage > filteredRecipes.length ? filteredRecipes.length : recipesPerPage * currentPage }} of {{filteredRecipes.length}} recipes</div>
            <div class="pagination">
               <div id="yw0" class="pagination">
                  <ul class="pagination">
                     <li :class="{disabled:currentPage == 1}"><a @click="currentPage > 1 ? currentPage-- : null" class="currentpage" style="cursor:pointer;">‹</a></li>
                     <li :class="{disabled:currentPage == filteredRecipes.length / recipesPerPage}"><a @click="currentPage < filteredRecipes.length / recipesPerPage ? currentPage++ : null" class="currentpage" style="cursor:pointer">›</a></li>
                  </ul>
               </div>
            </div>

            <div class="items">
               <div v-show="recipesLoading" style="text-align: center;"><img src="/images/loading.svg " /></div>
               <div
                     v-show="!recipesLoading" v-for="(recipe,recipeIndex) in filteredRecipes"

                     :key="recipe.id" :class="{open:recipe.preview}"
                     class="item"
                     >
                  <h3
                     v-if="recipeIndex+1 < currentPage * recipesPerPage && recipeIndex + 1 > (currentPage-1)*recipesPerPage"
                     @click="selectRecipe(recipe,$event)" style="" :class="{external: recipe.account != currentAccount}"
                     class="container-fluid " data-id="{{recipe.id}}" data-displayname="{{recipe.dsp_name}}" data-type="recipe">
                     <i class="fa fa-bars"></i>
                     <div class="pull-left recipeIcon">
                        <i v-show="recipe.type > 0" :class="'typeIcon '+ RECIPE_TYPES[recipe.type]"><img :src="'/images/typeIcons/'+RECIPE_TYPES[recipe.type]+'.png'"  /></i>
                        <i v-show="recipe.type < 1" class="fa">?</i>
                        <input type="checkbox" value="{{recipe.id}}" style="">
                     </div>
                     <div class="recipehandle recipe" style="" data-displayname="{{recipe.dsp_name}}" data-id="{{recipe.id}}" data-type="recipe">
                        <div style=""><span style="">{{recipe.dsp_name}}	</span></div>
                     </div>
                     <div class="btn-group pull-right" style="margin-right: 0px;">
                        <button type="button"  data-toggle="dropdown" class="btn btn-dropdown dropdown-toggle"><i class="fa fa-cog"></i></button>
                        <ul class="dropdown-menu">
                           <li>
                              <a @click="duplicateRecipe(recipe)" href="#" class="copyBtn currentpage">Duplicate Recipe</a>
                           </li>
                           <li>
                              <a @click="deleteRecipe(recipe)" class="" id="recdel_{{recipe.id}}">Delete Recipe</a>
                           </li>
                        </ul>
                     </div>

                     <div class="top">
                        {{recipe.name}}<small>&nbsp;&nbsp;&nbsp;</small>
                     </div>
                     <div class="bottom">{{new Date(recipe.modified).simpleDay()}}</div>
                  </h3>
               </div>
            </div>
         </div>
      </div>
   </form>
</template>

<script lang="ts">
   import '../../public/css/google-font-opensans.css';
   import '../../public/css/google-font-raleway.css';
   import '../../public/css/recipemanager.css';
   import '../../public/css/rcu.css';
   import {
      Options,
      Vue
   } from 'vue-class-component';
   import Globals from '../Globals';
   import { API_BASE_URL } from '../environment'
   import { Menu, Account, User } from '../Types';
   import Recipe from '../models/Recipe';
   import Axios from 'axios';
   import BootstrapModal from '../components/BootstrapModal.vue';

   @Options({
      components:{  },
      props: {
         currentAccount:Number,
         recipes:Array
      },
      watch: {
         recipes(val:Array<Recipe>) {
            this.initFilteredRecipes(val);
         }
      },
      emits: ['recipeSelected','recipeCreateSelected']
   })
   export default class RecipeList extends Vue {
         //props
         recipes!:Array<Recipe>;
         currentAccount!:number;


         //data variables
         sharedAccounts:Array<Account> = [];
         filteredRecipes:Array<Recipe> = [];
         nameSortDir:number = 1;
         dateSortDir:number = 1;
         filteredAccount:number = this.currentAccount;
         filteredType:number = 0;
         searchedRecipeTerm:string = '';
         recipesLoading:boolean = true;
         currentPage:number = 1;
         recipesPerPage:number = 50;

         //methods
         duplicateRecipe(recipe:Recipe) {
            //@todo: duplicate recipe function
            if(window.confirm('Duplicate recipe " '+recipe.dsp_name+' " ?')) alert('Sorry, duplicating a recipe is not avaiable yet.')
         }
         selectRecipe(recipe:Recipe,evt:any) {
            //check for recipe options click
            if(evt && evt.target && (evt.target.tagName.toLowerCase() == 'i' || evt.target.tagName.toLowerCase() == 'button' || evt.target.tagName.toLowerCase() == 'a')) return;

            this.$emit('recipeSelected',recipe);
         }

         deleteRecipe(recipe:Recipe) {
            //@todo: delete recipe function
            if(window.confirm('Are you sure you wish to delete '+recipe.dsp_name+'?' )) alert('Sorry, delete function not available yet.');
         }
         bulkDeleteRecipes() {
            //@todo: bulk delete recipe function
         }
         searchRecipes(term:string,recipes:Array<Recipe>|null = null) {
            if(term.length == 0) return this.filteredRecipes = [...(recipes || this.recipes)];
            if(term.length > 0) {
               this.filteredRecipes = (recipes || this.recipes).filter((recipe:Recipe)=>recipe.dsp_name.toLowerCase().indexOf(term.toLowerCase()) > -1 || recipe.name.toLowerCase().indexOf(term) > -1);
            }
         }
         sortByDate(order:number) {
            if(order == 0 && this.dateSortDir == 1) this.dateSortDir = -1;
            else if(order == 0 && this.dateSortDir != 1) this.dateSortDir = 1;
            order = order == 0 ? this.dateSortDir : order;
            console.log('date',order);
            this.filteredRecipes = this.filteredRecipes.sort((a,b)=>new Date(a.modified).getTime() > new Date(b.modified).getTime() ? order : order * -1);
         }
         sortByName(order:number) {
            if(order == 0 && this.nameSortDir == 1) this.nameSortDir = -1;
            else if(order == 0 && this.nameSortDir != 1) this.nameSortDir = 1;
            order = order == 0 ? this.nameSortDir : order;
            console.log('name',order);
            this.filteredRecipes = this.filteredRecipes.sort((a,b)=>a.dsp_name.toLowerCase() > b.dsp_name.toLowerCase() ? order : order * -1);
         }
         filterByType(type:number,recipes:Array<Recipe>|null = null) {
            if(!type || type == 0) return this.filteredRecipes = [...(recipes || this.recipes)];
            this.filteredRecipes = (recipes || this.recipes).filter((recipe:Recipe)=>recipe.type == type);
         }
         filterByAccount(accountId:number,recipes:Array<Recipe>|null = null) {
            if(!accountId || accountId == 0) return this.filteredRecipes = [...(recipes || this.recipes)];
            this.filteredRecipes = (recipes || this.recipes).filter((recipe:Recipe)=>recipe.account == accountId);
         }
         runFilters() {
            this.filterByAccount(this.filteredAccount);
            this.filterByType(this.filteredType,this.filteredRecipes);
            this.searchRecipes(this.searchedRecipeTerm,this.filteredRecipes);
            this.currentPage = 1;
         }
         initFilteredRecipes(recipes:Array<Recipe> = []) {
            if(recipes.length == 0) recipes = this.recipes;
            if(!recipes || recipes.length == 0) return;
            this.filteredAccount = this.currentAccount;
            this.runFilters();
            if(this.sharedAccounts.length == 0) this.getSharedAccountsFromRecipes(recipes);
            this.recipesLoading = false;
         }
         getSharedAccountsFromRecipes(recipes:Array<Recipe>) {
            let sharedAccountIds = [... new Set(recipes.map((rec:Recipe)=>rec.account))];
            return Axios.get(`${API_BASE_URL}/accounts?columns=id,name&id=${sharedAccountIds.join(',,')}`,Globals.axiosConf)
            .then(({data})=>{
               this.sharedAccounts = data.filter((sh:any)=>sh.id != this.currentAccount).map((sh:any)=>new Account(sh));
            }).catch(e=>{})
         }


         //computed
         get RECIPE_TYPES() { return Recipe.types; }

         //lifecycle
         async created() {
            await Globals.getMe();
            this.initFilteredRecipes();
            // if(this.currentAccount == -1) this.currentAccount = Globals.currentAccountId;
         }
         mounted() {

         }
   }
</script>
<style lang="less">
html {

   body.custom-background { background-color: #e6e6e6; }
   body a, h3,h4,p,div,span { font-family: "Open Sans" !important;}
   body .nav-soc { display:none; }
   body div.intro embed { height: 420px; }

   #menu-mainmenu li { position: relative;}
   #menu-mainmenu li ul.dropdown {
       display: none;
       background-color: #000;
   }
   #menu-mainmenu li:hover ul.dropdown {
       display: block;
       position: absolute;
       top: 20px;
       left: 0px;
       min-width: 150px;
       width: 100%;
       padding: 10px 3px;
       background-color: rgba(0,0,0,1);
       color: white;
       z-index:999;
   }
   #menu-mainmenu li ul.dropdown li {  padding: 10px; display: block; width: 100%}
   #menu-mainmenu li:hover ul.dropdown li:hover { background-color: rgba(255,255,255,0.2); cursor: pointer }
   #menu-mainmenu li ul.dropdown li a { color:white; font-size: 1em; padding: 0px; text-align: left }
   #menu-mainmenu li ul.dropdown li a:hover {
       background: none;
   }
   h1, .h1, h2, .h2, h3, .h3,p { margin: 0 0 0.3em 0; line-height: 1.4em; font-size: 1.2em }
   .cl-mcont.row { margin-left:0px; margin-right: 0px;}

   .items {
      .item {
         h3 { cursor: pointer;}
      }
   }

}
</style>

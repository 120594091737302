<template>
   <div id="recipemanager" class="container-fluid " >
      <h2>Manage Recipes</h2>
      <p class="rm_msg">Here in the recipe manager you can view all existing recipes in your account in addition to ones available to you from other well respected providers. You can also build your menu by using the interface on the right hand side.
      </p>

      <section :class="'stages stage_open_'+currentStage">
         <div class="stage1 stage" :class="{currentStage:currentStage == 1,pastStage:currentStage > 1,currentStages:currentStage >= 1 }">
            <RecipeList @recipeCreateSelected="createRecipe()" @recipeSelected="viewRecipe($event)" :recipes="recipes" :currentAccount="currentAccountId"></RecipeList>
         </div>
         <div class="stage2 stage" :class="{currentStage:currentStage == 2,pastStage:currentStage > 2,currentStages:currentStage >= 2,disabled:currentRecipe && currentAccountId != currentRecipe.account }">
            <div v-show="!currentRecipeLoaded">
               <img src="/images/loading.svg " />
            </div>
            <div v-if="currentRecipeLoaded" class="col-xs-12 meta">
               <div class="stage-actions">
                  <button @click="openStage(currentStage - 1)" class="btn"><i class="fa fa-chevron-left"></i> Back</button>
                  <button @click="openStage(currentStage + 1)" class="btn pull-right">Recipe Steps <i class="fa fa-chevron-right"></i></button>
                  <button v-show="currentAccountId == currentRecipe.account && currentRecipeChanged && currentStage == 2" @click="saveCurrentRecipe()" type="button" class="btn btn-primary pull-right">Update Recipe</button>
               </div>
              <div class="icons">
                  <div class="recTypeMsg">Beverage<br />Type</div>
                  <div @click="updateRecipe({type:1})" style=""  :class="{dis:currentRecipe.type != 1, active: currentRecipe.type == 1}"><i class="typeIcon tea"><img src="http://www.bkon-craftcloud.com/images/typeIcons/tea.png"></i> </div>
                  <div @click="updateRecipe({type:2})" style=""  :class="{dis:currentRecipe.type != 2, active: currentRecipe.type == 2}"><i class="typeIcon coffee"><img src="http://www.bkon-craftcloud.com/images/typeIcons/coffee.png"></i> </div>
                  <div @click="updateRecipe({type:3})" style=""  :class="{dis:currentRecipe.type != 3, active: currentRecipe.type == 3}"><i class="typeIcon spirits"><img src="http://www.bkon-craftcloud.com/images/typeIcons/spirits.png"></i> </div>
                  <div @click="updateRecipe({type:4})" style=""  :class="{dis:currentRecipe.type != 4, active: currentRecipe.type == 4}"><i class="typeIcon wine"><img src="http://www.bkon-craftcloud.com/images/typeIcons/wine.png"></i> </div>
                  <div @click="updateRecipe({type:5})" style=""  :class="{dis:currentRecipe.type != 5, active: currentRecipe.type == 5}"><i class="typeIcon cutlery"><img src="http://www.bkon-craftcloud.com/images/typeIcons/cutlery.png"></i> </div>
              </div>
              <br  />

               <div class="col-xs-12">
                   <div class="nameMeta bgbase col-xs-12" style="">
                       <div class="form-group">
                          <label class="control-label required" for="Recipe_name">Filename <span class="required">*</span></label>
                          <input @change="currentRecipeChanged = true" v-model="currentRecipe.name" maxlength="100" class="form-control" name="Recipe[name]" id="Recipe_name" type="text">
                          <p id="Recipe_name_em_" style="display:none" class="help-block"></p>
                       </div>
                       <div style="width: 40%; float: left;" class="form-group">
                          <label class="control-label required" for="Recipe_dsp_name">Display Name <span class="required">*</span></label>
                          <textarea @change="currentRecipeChanged = true" v-model="currentRecipe.dsp_name" maxlength="26" style="width: 100%;max-width :100%; max-height: 60px" class="form-control" name="Recipe[dsp_name]" id="Recipe_dsp_name"></textarea>
                          <p id="Recipe_dsp_name_em_" style="display:none" class="help-block"></p>
                       </div>
                       <div class="recipedisplay"><div style=""><span style=""></span></div></div>
                    </div>
                   <div class="bgbase col-xs-12" style="">
                       <label style="display: block; text-align: center;">Barista Talking Points</label>
                       <div style="margin-bottom: 0px; border: none" class="textAreaFocus form-group">
                          <textarea @change="currentRecipeChanged = true" v-model="currentRecipe.description" style="height: auto; width: auto; resize: none; border: thin solid gray; color: white; background-color: rgba(0,0,0,0.85); overflow: hidden; margin: auto" cols="35" rows="8" wrap="hard" maxlength="255" class="form-control" name="Recipe[description]" id="Recipe_description"></textarea>
                          <p id="Recipe_description_em_" style="display:none" class="help-block"></p>
                       </div>
                   </div>
               </div>
               <div class="col-xs-12">
                   <div class="desc bgbase" style="text-align: center;">
                       <div class="form-group">
                          <label class="control-label" for="Recipe_notes">Notes</label>
                          <textarea @change="currentRecipeChanged = true" v-model="currentRecipe.notes" style="height: 340px;" class="form-control" name="Recipe[notes]" id="Recipe_notes"></textarea>
                          <p id="Recipe_notes_em_" style="display:none" class="help-block"></p>
                       </div>
                    </div>
               </div>
               <div class="hidden col-xs-6">
                   <div class="image bgbase">
                       <label for="Recipe_image">Image</label>                    <input id="ytRecipe_image" type="hidden" value="" name="Recipe[image]"><input style="" name="Recipe[image]" id="Recipe_image" type="file">                    <div class="dispImg" style="width: 100px; position: relative;"><div class="close" style=""><i class="fa fa-times"></i></div><img style="max-width: 100%;" src="http://bkon-craftcloud.com/images/no_image.png" alt=""></div>
                       <input type="hidden" name="Recipe[image]" class="imgDel" value="0">
                   </div>

                   <div class="hidden sets bgbase col-xs-12" style="">
                       <h5 style="margin-top: 0px;">Tags</h5>
                       <div class="btn-group btn-group-justified" style="">
                           <div class="btn-group">
                               <button class="btn btn-primary chooseTags">Choose Tags</button>
                           </div>
                           <div class="btn-group">
                               <button class="btn addTag">Add Tag</button>
                           </div>
                       </div>
                       <div class="input-group addTag" style="display: none">
                           <input type="text" class="form-control">
                           <span class="input-group-btn"><button class="btn">Add</button></span>
                       </div>
                       <div class="input-group chooseTags" style="display: block;">
                           <select class="form-control"><option value="">Select Tag ...</option><option value="5">Test Set 1</option><option value="6">Test Set 2</option><option value="107">adsfa asdf asdf</option><option value="110">featured</option><option value="112">myTag</option><option value="113">fine</option><option value="141">Green </option><option value="142">Tea</option><option value="143">Iced </option><option value="144">Hot </option><option value="145">Mint</option><option value="150">aaa</option></select>
                       </div>
                       <div class="tagcloud">
                       </div>
                   </div>
               </div>

               <p class="clearfix"></p>
               <div class="rowa">
                   <div class="col-xs-12">
                      <div class="ingredients bgbase">
                          <h2>Add A Custom Ingredient
                              <small>
                                 <a style="font-size: 10px; text-decoration: underline" target="_blank" href="https://legacy.bkon-craftcloud.com/ingredient/admin">Manage Ingredients</a>
                              </small>
                           </h2>
                          <div class="ipopup" style="float: right;"><input @change="updateRecipe({ingredientsPopup:$event.target.checked})" :checked="currentRecipe.ingredientsPopup" type="checkbox" value="1"  /> Ingredients Popup</div>
                          <div class="col-xs-10 filters" style="margin-bottom: 5px; ">


                              <input @change="filterIngredients()" checked="checked" type="checkbox" value="botainicals" /> Botanical
                              <input @change="filterIngredients()" checked="checked" type="checkbox" style="margin-left: 20px;" value="fruits" /> Fruit
                              <input @change="filterIngredients()" checked="checked" type="checkbox" style="margin-left: 20px;" value="juices" /> Juice
                              <input @change="filterIngredients()" checked="checked" type="checkbox" style="margin-left: 20px;" value="spirits" /> Spirit
                              <input @change="filterIngredients()" checked="checked" type="checkbox" style="margin-left: 20px;" value="wine" /> Wine
                              <input @change="filterIngredients()" checked="checked" type="checkbox" style="margin-left: 20px;" value="sweeteners" /> Sweetener
                              <input @change="filterIngredients()" checked="checked" type="checkbox" style="margin-left: 20px;" value="other" /> Other

                              <div class="input-group" style="float: right; display: inline-block; width: 400px; margin: 0px; ">
                                 <select id="ingredientAdd" class="form-control" placeholder="Ingredient description (optional)">
                                    <option value=''>Type Ingredient</option>";
                                    <!-- <option v-for="ingredient in ingredients" :value="ingredient">{{ingredient}}</option> -->
                                    <optgroup label='All'>";

                                    </optgroup>";

                                 </select>
                                       <!--                    <div class="input-group-btn"><button class="btn btn-primary" type="button">Add Ingredient</button></div>-->
                              </div>
                          </div>
                          <div class="col-xs-12" style="border-top: thin solid #efefe7; padding-top: 10px;">
                              <div class="ingredientsUi">
                                 <div v-for="(ingredient,ind) in currentRecipe.ingredients" :data-ingredient='ind' class='row' style='position: relative'>
                                    <div @click="currentRecipe.ingredients.splice(ind,1); currentRecipeChanged = true;" style='right: auto; left: -15px' class='close'></div>
                                     <div class='col-xs-2'>{{ingredient.name}}</div>
                                     <div class='col-xs-2'>
                                        <input style='max-width:50px; float: left' type='text' class='form-control ingredientAmount small' :data-ingredient='ind' :value='parseFloat(ingredient.small)' />
                                        <select class='form-control units' style='float: left;max-width:45px; padding: 1%'>
                                           <option value='g'>g</option>
                                           <option value='oz'>oz</option>
                                           <option value='mL'>mL</option>
                                        </select>
                                     </div>
                                     <div class='col-xs-2'>
                                        <input style='max-width:50px; float:left' type='text' class='form-control ingredientAmount medium' :data-ingredient='ind' :value='parseFloat(ingredient.medium)' />
                                        <select class='form-control units' style='float: left;max-width:45px; padding: 1%'>
                                           <option value='g'>g</option>
                                           <option value='oz'>oz</option>
                                           <option value='mL'>mL</option>
                                        </select>
                                     </div>
                                     <div class='col-xs-2'>
                                        <input style='max-width:50px; float:left' type='text' class='form-control ingredientAmount large' :data-ingredient='ind' :value='parseFloat(ingredient.large)' />
                                        <select class='form-control units' style='float: left;max-width:45px; padding: 1%'>
                                           <option value='g'>g</option>
                                           <option value='oz'>oz</option>
                                           <option value='mL'>mL</option>
                                        </select>
                                     </div>
                                     <div class='col-xs-4'>
                                        <input style='' type='text' class='form-control ingredientText' :data-ingredient='ind' :value='ingredient.text' placeholder='Ingredient description (optional)' /> </div>
                                  </div>
                              </div>
                          </div>
                          <p class="clearfix"></p>
                      </div>

                      <button v-show="currentAccountId == currentRecipe.account" @click="saveCurrentRecipe()" type="button" class="btn btn-primary">Update Recipe</button>
                   </div>
               </div>

               <button @click="openStage(3)" class="btn btn-block pull-right">Recipe Steps <i class="fa fa-chevron-right"></i></button>
            </div>
         </div>
         <div class="stage3 stage" :class="{currentStage: currentStage == 3,pastStage:currentStage > 3,currentStages:currentStage >= 3 }">
            <div class="stage-actions">
               <button @click="openStage(currentStage - 1)" class="btn"><i class="fa fa-chevron-left"></i> Back</button>
               <button v-show="currentAccountId == currentRecipe.account && currentRecipeChanged" @click="saveCurrentRecipe()" type="button" class="btn btn-primary pull-right">Update Recipe</button>
            </div>
            <RecipeEditor v-if="currentRecipe.id > 0 || newRecipeFlag" @recipeUpdate="updateRecipe({sequences:$event.sequences})" v-model:recipe="currentRecipe"></RecipeEditor>
            <button v-show="currentAccountId == currentRecipe.id" @click="saveCurrentRecipe()" type="button" class="btn btn-primary pull-right">Save Recipe</button>
         </div>
         <div class="stage4 stage" :class="{currentStage: currentStage == 4,pastStage:currentStage > 4,currentStages:currentStage >= 4 }"></div>
      </section>

      <p class="clearfix"></p>
   </div>

   <BootstrapModal @modalClosed="" @modalSavedAction="importRecipe()" :title="'Import Base Recipe'" :modalId="'importRecipeModal'" saveButton="Import Recipe">
      <RecipeImportModal @recipeSelected="importRecipe($event)" :recipes="importRecipes"></RecipeImportModal>
   </BootstrapModal>
</template>

<script lang="ts">
   import '../../public/css/google-font-opensans.css';
   import '../../public/css/google-font-raleway.css';
   import '../../public/css/recipemanager.css';
   import '../../public/css/rcu.css';
   import {
      Options,
      Vue
   } from 'vue-class-component';
   import Globals from '../Globals';
   import { API_BASE_URL } from '../environment'
   import { Menu, Account,User } from '../Types';
   import Recipe from '../models/Recipe';
   import RecipeEditor from '../components/RecipeEditor.vue';
   import RecipeList from '../components/RecipeList.vue';
   import Axios from 'axios';
   import BootstrapModal from '../components/BootstrapModal.vue';
   import RecipeImportModal from '../components/RecipeImportModal.vue';

   @Options({components:{RecipeEditor,BootstrapModal,RecipeList,RecipeImportModal}})
   export default class RecipeManager extends Vue {
      //props


      //data variables
      newRecipeFlag:boolean = false;
      currentAccountId:number = 0;
      currentStage:number = 1;
      ingredients:Array<any> = [];
      baseRecipes:Array<Recipe> = [];
      recipes:Array<Recipe> = [];
      recipePreview:number = 0;
      sharedAccounts:Array<Account> = [];
      filteredRecipes:Array<Recipe> = [];
      nameSortDir:number = 1;
      dateSortDir:number = 1;
      currentRecipe:Recipe = new Recipe;
      currentRecipeLoaded:boolean = false;
      currentRecipeChanged:boolean = false;
      currentImportRecipeSelected:number = 0;

      //methods
      openStage(stage:number) {
         //check for save
         if(!this.checkForSaving(this.currentStage)) return;

         this.currentStage = stage;
      }
      checkForSaving(currentStage:number) {
         if(currentStage == 1) { return true; }
         if(currentStage == 2) {
            if(this.currentRecipeChanged) return window.confirm('Changes UNSAVED. Are you sure you wish to proceed without saving changes?');
            else return true;
         }
         if(currentStage == 3) {
            if(this.currentRecipeChanged) return window.confirm('Changes UNSAVED. Are you sure you wish to proceed without saving changes?');
            else return true;
         }
         if(currentStage == 4) { return true; }
      }
      clearRecipe() {
        //clear out recipe specific flags
        this.currentRecipe = new Recipe;
        this.currentRecipeLoaded = false;
        this.currentRecipeChanged = false;
      }
      importRecipe(importedRecipe:Recipe):Promise<any> {
         if(!importedRecipe.id) return new Promise((resolve,reject)=>{ reject(); });
         this.currentRecipeLoaded = false;
         this.openStage(2);
         //@ts-ignore
         $('#importRecipeModal').modal('hide');
         return Axios.get(`${API_BASE_URL}/recipes/${importedRecipe.id}`,Globals.axiosConf)
         .then(({data})=>{
            this.currentRecipe = new Recipe(data);
            this.currentRecipe.account = Globals.currentAccountId;
            this.currentRecipe.id = 0;
            this.currentRecipeLoaded = true;
            this.newRecipeFlag = true;
         }).catch(e=>{})
      }
      createRecipe() {
         this.currentRecipe = new Recipe();
         this.currentRecipe.account = Globals.currentAccountId;
         this.currentRecipeLoaded = true;
         this.newRecipeFlag = true;
         this.openStage(2);
      }
      duplicateRecipe(recipe:Recipe) {
         //@todo: duplicate recipe function
         if(window.confirm('Duplicate recipe " '+recipe.dsp_name+' " ?')) alert('Sorry, duplicating a recipe is not avaiable yet.')
      }
      viewRecipe(recipe:Recipe) {
         //open panel
         //clear out recipe specific flags
         this.clearRecipe();
         this.openStage(2)

         //get full recipe from api, display meta, set current recipe
         return Axios.get(`${API_BASE_URL}/recipes/${recipe.id}`,Globals.axiosConf)
         .then(({data})=>{
            this.currentRecipe = new Recipe(data);
            this.currentRecipeLoaded = true;

            //set display name
            //@ts-ignore
            setTimeout(()=>{ document.querySelector('.recipedisplay span').innerHTML = data.dsp_name.replace(/\n/g, "<br />"); },500);

            //unit degree text @todo: set celciusmode from user settings
            //@ts-ignore
         	$("span.unit").html(window.celciusMode ? 'C' : 'F');

            //set ingredients
            setTimeout(()=>{
               //@ts-ignore
               window.Rcu.ingredientInit(this.ingredients);
            },500);

         }).catch(e=>{ console.log(e); alert('Error getting full recipe. Please refresh and try again.')})
      }
      updateRecipe(data:any) {
         if(this.currentRecipe.account != Globals.currentAccountId) return;
         Object.keys(data).forEach((key:string)=> {
            //@ts-ignore
            try { this.currentRecipe[key] = data[key]; } catch(e) { alert(' error changing recipe'); }
            this.currentRecipeChanged = true;
         });
      }
      saveCurrentRecipe() {
         if(Globals.currentAccountId != this.currentRecipe.account) return window.alert('You cannot edit a recipe that\'s not yours');
         this.currentRecipeLoaded = false;
         this.currentRecipe.modified = new Date();
         Axios[this.currentRecipe.id > 0 ? 'put' : 'post'](`${API_BASE_URL}/recipes${this.currentRecipe.id > 0 ? '/'+this.currentRecipe.id : ''}`,this.currentRecipe,Globals.axiosConf)
         .then(({data})=>{
            //reset flags
            this.currentRecipeChanged = false;
            this.currentRecipeLoaded = true;
            this.newRecipeFlag = false;
            this.currentRecipe.id = data.id;

            //update list recipe
            let lrec = this.recipes.find(rec=>rec.id == data.id);
            let flrec = this.filteredRecipes.find(rec=>rec.id == data.id);
            if(lrec) lrec.update(data);
            if(flrec) flrec.update(data);
         }).catch(e=>alert('Error saving recipe. Please refresh and try again.'))
      }
      deleteRecipe(recipe:Recipe) {
         //@todo: delete recipe function
         if(window.confirm('Are you sure you wish to delete '+recipe.dsp_name+'?' )) alert('Sorry, delete function not available yet.');
      }
      bulkDeleteRecipes() {
         //@todo: bulk delete recipe function
      }
      searchRecipes(term:string) {
         if(term.length == 0) return this.filteredRecipes = [...this.recipes];
         if(term.length > 0) {
            this.filteredRecipes = this.recipes.filter((recipe:Recipe)=>recipe.dsp_name.toLowerCase().indexOf(term.toLowerCase()) > -1 || recipe.name.toLowerCase().indexOf(term) > -1);
         }
      }
      sortByDate(order:number) {
         if(order == 0 && this.dateSortDir == 1) this.dateSortDir = -1;
         else if(order == 0 && this.dateSortDir != 1) this.dateSortDir = 1;
         order = order == 0 ? this.dateSortDir : order;
         console.log('date',order);
         this.filteredRecipes = this.filteredRecipes.sort((a,b)=>new Date(a.modified).getTime() > new Date(b.modified).getTime() ? order : order * -1);
      }
      sortByName(order:number) {
         if(order == 0 && this.nameSortDir == 1) this.nameSortDir = -1;
         else if(order == 0 && this.nameSortDir != 1) this.nameSortDir = 1;
         order = order == 0 ? this.nameSortDir : order;
         console.log('name',order);
         this.filteredRecipes = this.filteredRecipes.sort((a,b)=>a.dsp_name.toLowerCase() > b.dsp_name.toLowerCase() ? order : order * -1);
      }
      filterByType(type:number) {
         if(!type) return this.filteredRecipes = [...this.recipes];
         this.filteredRecipes = this.recipes.filter((recipe:Recipe)=>recipe.type == type);
      }
      filterByAccount(accountId:number) {
         if(!accountId) return this.filteredRecipes = [...this.recipes];
         this.filteredRecipes = this.recipes.filter((recipe:Recipe)=>recipe.account == accountId);
      }
      filterIngredients() {
         //@ts-ignore
         window.Rcu.filterIngredients()
      }

      //computed
      get RECIPE_TYPES() { return Recipe.types; }
      get importRecipes() { return this.recipes.filter((rec:Recipe)=>rec.import); }


      //lifecycle
      async created() {
         await Globals.getMe();

         this.currentAccountId = Globals.currentAccountId;

         //get recipes
         Axios.get(`${API_BASE_URL}/recipes/shared?&columns=id,name,type,dsp_name,modified,account,account.import`,Globals.axiosConf)
         .then(({data})=>{
            this.recipes = data.map((rec:any)=>new Recipe(rec));
            this.baseRecipes = data.filter((rec:any)=>rec.import).map((rec:any)=>new Recipe(rec));
            this.filteredRecipes = [...this.recipes];
         }).catch(e=>{ console.error(e); });

         //get ingredients
         Axios.get(`${API_BASE_URL}/ingredients?account=0,,${Globals.currentAccountId}`,Globals.axiosConf)
         .then(({data})=>{
            // this.ingredients = data;
            //@ts-ignore
            window.ingjs = data.map(ing=>{ ing.category = ing.category.toLowerCase(); return ing;}).toObjectWithKeyAndArray('category');
         }).catch(e=>{});

         if(this.$route.params && this.$route.params.id) {
            let tmpRec = new Recipe({id:this.$route.params.id})
            this.viewRecipe(tmpRec)
            .then(()=>{
               this.openStage(3);
            }).catch(e=>{})
         }
      }
      mounted() {
         let $this = this;

        //ingredientAdd
        //@ts-ignore
        $(document).on('change','#ingredientAdd',function(e) {
           //@ts-ignore
            var cur = $(this);
            //@ts-ignore
            if($(this).val().length > 2) {
                var notIn = true;
                //@ts-ignore
                $.each(window.Rcu.ingredients,function(ind,val) {
                    if(val.name == cur.val()) {
                        notIn = false;
                    }
                });
                if(notIn) {
                   //@ts-ignore
                    $this.currentRecipe.ingredients.push({name:$(this).val(),small:0,medium:0,large:0,text:''});
                    $this.updateRecipe({ingredientsPopup: 1,ingredients:$this.currentRecipe.ingredients});
                }
            }
        });



        //ingredientsChangeUnits
        //@ts-ignore
        $(document).on('change','.ingredients select.units',function(e) {
           //@ts-ignore
            $(this).parent().children('.ingredientAmount').trigger('change');
        });

         //barista talking points enforcement
         //@ts-ignore
         $(document).on('keydown','textarea#Recipe_description',window.talkingpoints);

        //display name enforcement
        //@ts-ignore
        $(document).on('keydown','textarea#Recipe_dsp_name',window.DspName);
        //@ts-ignore
        $(document).on('keyup','textarea#Recipe_dsp_name',function(e) {
           //@ts-ignore
           $('.recipedisplay span').html($(this).val().replace(/\n/g, "<br />"));
        });
      }

   }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
   html {

      body {

         .disabled {

            .icons,input,button,a,.typeIcon,textarea,select {
               pointer-events: none;
               opacity: 0.5;
               cursor: not-allowed;
            }
            .stage-actions button { opacity: 1; cursor: pointer; pointer-events: all;}
         }
      }
   }

   html {
      body {

         .stages {
               display: flex;
               .stage-actions {
                  padding: 15px 0;
               }

               .stage1, .stage2, .stage3, .stage4 {
                  max-width: 0px;
                  flex: 1;
                  transition: .5s linear all;
                  opacity: 0;
                  padding: 0 15px;
               }
               .pastStage {
                  opacity: 0.3;
               }
               .currentStage { z-index: 999; }
               &.stage_open_1 {
                  .stage1 {
                     max-width: 1200px;
                     opacity: 1;
                  }
               }
               &.stage_open_2 {

                  .stage1 {
                     max-width: 40%
                  }
                  .stage2 {
                     max-width: 50%;
                     opacity: 1;
                  }

               }
               &.stage_open_3 {

                  .stage1,.stage2 {
                     max-width: 25%
                  }
                  .stage3 {
                     max-width: 50%;
                     opacity: 1;
                  }
               }
               &.stage_open_4 {

                  .stage1.stage2,.stage3 {
                     max-width: 17%
                  }
                  .stage3 {
                     max-width: 50%;
                     opacity: 1;
                  }

               }

         }
      }
   }


</style>

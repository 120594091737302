
   import '../../public/css/google-font-opensans.css';
   import '../../public/css/google-font-raleway.css';
   import '../../public/css/recipemanager.css';
   import '../../public/css/rcu.css';
   import {
      Options,
      Vue
   } from 'vue-class-component';
   import Globals from '../Globals';
   import { API_BASE_URL } from '../environment'
   import { Menu, Account, User } from '../Types';
   import Recipe from '../models/Recipe';
   import Axios from 'axios';
   import BootstrapModal from '../components/BootstrapModal.vue';

   @Options({
      components:{  },
      props: {
         currentAccount:Number,
         recipes:Array
      },
      watch: {
         recipes(val:Array<Recipe>) {
            this.initFilteredRecipes(val);
         }
      },
      emits: ['recipeSelected','recipeCreateSelected']
   })
   export default class RecipeList extends Vue {
         //props
         recipes!:Array<Recipe>;
         currentAccount!:number;


         //data variables
         sharedAccounts:Array<Account> = [];
         filteredRecipes:Array<Recipe> = [];
         nameSortDir:number = 1;
         dateSortDir:number = 1;
         filteredAccount:number = this.currentAccount;
         filteredType:number = 0;
         searchedRecipeTerm:string = '';
         recipesLoading:boolean = true;
         currentPage:number = 1;
         recipesPerPage:number = 50;

         //methods
         duplicateRecipe(recipe:Recipe) {
            //@todo: duplicate recipe function
            if(window.confirm('Duplicate recipe " '+recipe.dsp_name+' " ?')) alert('Sorry, duplicating a recipe is not avaiable yet.')
         }
         selectRecipe(recipe:Recipe,evt:any) {
            //check for recipe options click
            if(evt && evt.target && (evt.target.tagName.toLowerCase() == 'i' || evt.target.tagName.toLowerCase() == 'button' || evt.target.tagName.toLowerCase() == 'a')) return;

            this.$emit('recipeSelected',recipe);
         }

         deleteRecipe(recipe:Recipe) {
            //@todo: delete recipe function
            if(window.confirm('Are you sure you wish to delete '+recipe.dsp_name+'?' )) alert('Sorry, delete function not available yet.');
         }
         bulkDeleteRecipes() {
            //@todo: bulk delete recipe function
         }
         searchRecipes(term:string,recipes:Array<Recipe>|null = null) {
            if(term.length == 0) return this.filteredRecipes = [...(recipes || this.recipes)];
            if(term.length > 0) {
               this.filteredRecipes = (recipes || this.recipes).filter((recipe:Recipe)=>recipe.dsp_name.toLowerCase().indexOf(term.toLowerCase()) > -1 || recipe.name.toLowerCase().indexOf(term) > -1);
            }
         }
         sortByDate(order:number) {
            if(order == 0 && this.dateSortDir == 1) this.dateSortDir = -1;
            else if(order == 0 && this.dateSortDir != 1) this.dateSortDir = 1;
            order = order == 0 ? this.dateSortDir : order;
            console.log('date',order);
            this.filteredRecipes = this.filteredRecipes.sort((a,b)=>new Date(a.modified).getTime() > new Date(b.modified).getTime() ? order : order * -1);
         }
         sortByName(order:number) {
            if(order == 0 && this.nameSortDir == 1) this.nameSortDir = -1;
            else if(order == 0 && this.nameSortDir != 1) this.nameSortDir = 1;
            order = order == 0 ? this.nameSortDir : order;
            console.log('name',order);
            this.filteredRecipes = this.filteredRecipes.sort((a,b)=>a.dsp_name.toLowerCase() > b.dsp_name.toLowerCase() ? order : order * -1);
         }
         filterByType(type:number,recipes:Array<Recipe>|null = null) {
            if(!type || type == 0) return this.filteredRecipes = [...(recipes || this.recipes)];
            this.filteredRecipes = (recipes || this.recipes).filter((recipe:Recipe)=>recipe.type == type);
         }
         filterByAccount(accountId:number,recipes:Array<Recipe>|null = null) {
            if(!accountId || accountId == 0) return this.filteredRecipes = [...(recipes || this.recipes)];
            this.filteredRecipes = (recipes || this.recipes).filter((recipe:Recipe)=>recipe.account == accountId);
         }
         runFilters() {
            this.filterByAccount(this.filteredAccount);
            this.filterByType(this.filteredType,this.filteredRecipes);
            this.searchRecipes(this.searchedRecipeTerm,this.filteredRecipes);
            this.currentPage = 1;
         }
         initFilteredRecipes(recipes:Array<Recipe> = []) {
            if(recipes.length == 0) recipes = this.recipes;
            if(!recipes || recipes.length == 0) return;
            this.filteredAccount = this.currentAccount;
            this.runFilters();
            if(this.sharedAccounts.length == 0) this.getSharedAccountsFromRecipes(recipes);
            this.recipesLoading = false;
         }
         getSharedAccountsFromRecipes(recipes:Array<Recipe>) {
            let sharedAccountIds = [... new Set(recipes.map((rec:Recipe)=>rec.account))];
            return Axios.get(`${API_BASE_URL}/accounts?columns=id,name&id=${sharedAccountIds.join(',,')}`,Globals.axiosConf)
            .then(({data})=>{
               this.sharedAccounts = data.filter((sh:any)=>sh.id != this.currentAccount).map((sh:any)=>new Account(sh));
            }).catch(e=>{})
         }


         //computed
         get RECIPE_TYPES() { return Recipe.types; }

         //lifecycle
         async created() {
            await Globals.getMe();
            this.initFilteredRecipes();
            // if(this.currentAccount == -1) this.currentAccount = Globals.currentAccountId;
         }
         mounted() {

         }
   }

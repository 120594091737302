<template>

<div class="page-head">
   <div class="col-xs-9" style="max-width: 800px;">
      <h1>{{recipe.dsp_name}}</h1>
      <p style="">Create each step in your recipe by adding them to their positions. <br>
Not ready to create from scratch? Use our Test Flight Wizard to get a series of recipe variations to test.</p>
   </div>

   <div id="page-wrap" class="notop">
      <div class="row notop">
         <div class="col-xs-12">
            <ul id="main_recipe">
               <li class="ui-state-disabled heading row notop">
                  <div class="col-xs-12 input_values">
                     <div class="parameter bt row theader">
                        <div class="col-xs-3">
                           <h5 style="color: black; font-weight: normal; padding-top: 20px">Total Volume:</h5>
                        </div>
                        <div class="col-xs-3" style="">
                           <input @change="updateSequences()" v-model="portions[0].name" type="text" class="form-control sname" />
                           <i onclick="window.General.copyValues('small','medium');" class="fa fa-arrow-right"></i>
                           <div class="count small" style="margin-left: -35px; font-size: 100%;"><span>0</span>mL</div>
                        </div>
                        <div class="col-xs-3">
                           <i onclick="window.General.copyValues('medium','small');" class="fa fa-arrow-left"></i>
                           <input @change="updateSequences()" v-model="portions[1].name" type="text" class="form-control mname" />
                           <i onclick="window.General.copyValues('medium','large');" class="fa fa-arrow-right"></i>
                           <div class="count medium" style=""><span>0</span>mL</div>
                        </div>
                        <div class="col-xs-3">
                           <i onclick="window.General.copyValues('large','medium');" class="fa fa-arrow-left"></i>
                           <input @change="updateSequences()" v-model="portions[2].name" type="text" class="form-control lname" />
                           <div class="count large" style="margin-left: 50px"><span>0</span>mL</div>
                        </div>
                     </div>
                  </div>
               </li>
               <li v-if="portions[0].sequences.length == 0">
                  <button @click="addStartStep()" class="btn btn-info"><i class="fa fa-plus"></i> Start</button>
               </li>

               <li v-for="(sequence,seqIndex) in portions[0].sequences" :class="'row '+sequence.type">
                  <div v-if="sequence.type == 'dialog'">
                     <div class="col-xs-12 title">
                        <div class="stepTrash"><i @click="removeStep(seqIndex)" class="fa fa-trash pull-right float-right"></i></div>
                        Dialog
                     </div>
                     <div class="col-xs-12 input_values">
                        <div data-param="text" class="parameter text row">
                           <div class="col-xs-3">
                              <h5>Text<small><i data-content="Text to be show as a pop message on the machine. Could be instructions, cross selling products, etc." class="fa fa-question"></i></small></h5>
                           </div>
                           <div class="col-xs-9">
                              <textarea v-model="sequence.values.text" type="text" class="form-control small medium large"></textarea>
                           </div>
                        </div>

                     </div>
                  </div>
                  <div v-else-if="['pg','vc','start','fr'].indexOf(sequence.type) > -1">
                     <div class="col-xs-12 title">
                        <div class="stepTrash"><i @click="removeStep(seqIndex)" class="fa fa-trash pull-right float-right"></i></div>
                        {{parameters[sequence.type].title}}
                     </div>
                     <div class="col-xs-12 input_values">
                        <div v-for="param in parameters[sequence.type].params" :data-param="param.key" :class="'parameter row '+param.key">
                           <div class="col-xs-3">
                              <h5>{{param.title}} <small>(&deg;<span class='unit'>{{param.unit}}</span>)
                                 <i data-content="Temperature set once for entire recipe." class="fa fa-question"></i></small>
                              </h5>
                           </div>
                           <div v-for="portionIndex in [0,1,2]" class="col-xs-3">
                              <input v-if="param.type == 'checkbox'" @change="updatePortionValue(portionIndex,seqIndex,param.key,$event.target.checked)" :checked="portions[portionIndex].sequences[seqIndex].values[param.key] == 1" type="checkbox" />
                              <input v-else @change="updatePortionValue(portionIndex,seqIndex,param.key,$event.target.value)" :value="portions[portionIndex].sequences[seqIndex].values[param.key]" type="text" :class="{'form-control':portionIndex > -1,'small':portionIndex == 0,'medium':portionIndex == 1,'large':portionIndex == 2}"  />
                           </div>

                        </div>
                     </div>
                  </div>
                  <div v-else>
                     <div class="stepTrash"><i @click="removeStep(seqIndex)" class="fa fa-trash pull-right float-right"></i></div>
                     ( {{ sequence.type }} )
                  </div>
                  <!-- <button @click="addStartStep()" class="btn btn-info"><i class="fa fa-plus"></i> Start</button> -->
                  <div class="addSteps">
                     <button @click="addFillStep(seqIndex+1)" class="btn btn-info"><i class="fa fa-plus"></i> Fill</button>
                     <button @click="addVacStep(seqIndex+1)" class="btn btn-info"><i class="fa fa-plus"></i> Vacuum</button>
                     <button @click="addPurgeStep(seqIndex+1)" class="btn btn-info"><i class="fa fa-plus"></i> Purge</button>
                     <button @click="addDialogStep(seqIndex+1)" class="btn btn-info"><i class="fa fa-plus"></i> Dialog</button>
                  </div>
               </li>

            </ul>
         </div>
      </div>
   </div>
</div>
</template>

<script lang="ts">
import "../../public/css/rcu.css";
import Recipe from "../models/Recipe";
import {
   Options,
   Vue
} from 'vue-class-component';

@Options({
   props: {
      recipe: Recipe
   },
   emits: ['recipeUpdate'],
   watch: {
      recipe(val) {
         this.initStuff();
      }
   }
})
export default class RecipeEditor extends Vue {
   //props
   recipe!: Recipe;

   //data variables
   portions:Array<any> = [{name:'',sequences:[]},{name:'',sequences:[]},{name:'',sequences:[]}];
   parameters:any = {
      dialog: {
         key: 'dialog',
         title: 'Dialog',
         params: [
            {
               key:'text',
               title:'Text',
               hint: 'Text to be show as a pop message on the machine. Could be instructions, cross selling products, etc.',
               unit: ''
            }
         ]
      },
      start: {
         key: 'start',
         title: 'Start',
         params: [
            {
               key:'tmp',
               title:'Water Temperature',
               hint: 'Temperature set once for entire recipe.',
               unit: 'F'
            }
         ]
      },
      vc: {
         key: 'vc',
         title: 'Vacuum',
         params: [
            {
               key:'ps',
               title:'Vacuum Setpoint',
               hint: 'Reverse Pressure (negative) you want. Enter only the number (0-60)',
               unit: 'kPs'
            },
            {
               key:'tm',
               title:'Vacuum Hold Time',
               hint: "Water holding Time at the pressure specified above.",
               unit: 's'
            },
            {
               key:'ap',
               title:'ATM Pause',
               hint: 'Time to let the water sit with no pressure.',
               unit: 's'
            }

         ]
      },
      fr: {
         key: 'fr',
         title: 'Fill',
         params: [
            {
               key:'fwv',
               title:'Fill Value',
               hint: 'Volume of water to dispense straight down.',
               unit: 'mL'
            },
            {
               key:'rwv',
               title:'Rinse Value',
               hint: 'Volume of water to be dispensed through the Rinse port.',
               unit: 'mL'
            },
            {
               key:'ap',
               title:'ATM Pause',
               hint: 'ime to let the water sit with no pressure.',
               unit: 's'
            }

         ]
      },
      pg: {
         key: 'pg',
         title: 'Purge',
         params: [
            {
               key:'ps',
               title:'Pressure Setpoint',
               hint: 'Pressure you want to push the water out of the chamber. The Pressure Control check box must be checked to be effective.',
               unit: 'kPa'
            },
            {
               key:'tm',
               title:'Pressure Hold Time',
               hint: 'Maximum time to push the water out at the specified pressure.',
               unit: 's'
            },
            {
               key:'rwv',
               title:'Rinse',
               hint: 'Volume of water used to rinse while purging.',
               unit: 'mL'
            },
            {
               key:'manstop',
               title:'Manual Stop',
               hint: 'Enable a dialog to stop Purge manually.',
               unit: '',
               type:'checkbox'
            },
            {
               key:'contr',
               title:'Purge Control',
               hint: 'Enable Pressure Control and Purge Detection.',
               unit: '',
               type: 'checkbox'
            },
            {
               key:'det',
               title:'Purge Detection',
               hint: 'Detect if all water has been purged. Require Pressure Control enabled.',
               unit: '',
               type:'checkbox'
            },
            {
               key:'dl',
               title:'Delay',
               hint: 'Time delay to start rinse on purge',
               unit: 's'
            }

         ]
      }
   };

   //methods
   removeStep(seqIndex:number) {
      if(!window.confirm("Are you sure you wish to remove this step?")) return;

      for(let i = 0; i < 3; i++) {
         this.portions[i].sequences.splice(seqIndex,1);
      }
      this.updateSequences();
   }
   updateSequences() {
      this.recipe.sequences = { portions: this.portions };
      this.$emit('recipeUpdate',this.recipe);
   }
   updatePortionValue(portionIndex:number,seqIndex:number,paramKey:string,val:string) {
      this.portions[portionIndex].sequences[seqIndex].values[paramKey] = val;
      this.updateSequences();
   }
   addFillStep(seqIndex:number) {
      for(let i = 0; i < 3; i++) {
         this.portions[i].sequences.splice(seqIndex,0,{type:'fr',values:{ fwv:'',rwv:'',ap:''}});
      }
   }
   addVacStep(seqIndex:number) {
      for(let i = 0; i < 3; i++) {
         this.portions[i].sequences.splice(seqIndex,0,{type:'vc',values:{ps:'',tm:'',ap:''}});
      }
   }
   addPurgeStep(seqIndex:number) {
      for(let i = 0; i < 3; i++) {
         this.portions[i].sequences.splice(seqIndex,0,{type:'pg',values:{det:1,contr:1,manstop:0,dl:'',tm:'',ps:''}});
      }
   }
   addDialogStep(seqIndex:number) {
      for(let i = 0; i < 3; i++) {
         this.portions[i].sequences.splice(seqIndex,0,{type:'dialog',values:{text:''}});
      }
   }
   addStartStep() {
      for(let i = 0; i < 3; i++) {
         this.portions[i].sequences.splice(0,0,{type:'start',values:{tmp:''}});
      }
   }
   initStuff() {
      if(!this.recipe.sequences.portions) this.recipe.sequences.portions = [];
      for(let a =0; a < 3; a++) {
         if(!this.recipe.sequences.portions[a]) this.recipe.sequences.portions[a] = {name:'',sequences:[]};
         for(let b =0; b < this.recipe.sequences.portions[0].sequences.length; b++) {
            if(a > 0 && !this.recipe.sequences.portions[a].sequences[b]) this.recipe.sequences.portions[a].sequences[b] = {...this.recipe.sequences.portions[0].sequences[b],values:{}}
         }
      }


      if(this.recipe && this.recipe.sequences && this.recipe.sequences.portions) {
         this.recipe.sequences.portions.forEach((portion:any,portionIndex:number)=>{
            this.portions[portionIndex] = portion;
         })
         this.portions.forEach((portion:any)=>{
            portion.sequences.forEach((sequence:any)=>{
               if(!sequence.values) sequence.values = {};
               if(this.parameters[sequence.type]) {
                  this.parameters[sequence.type].params.forEach((param:any)=>{
                     if(!sequence.values[param.key]) sequence.values[param.key] = '';
                  })
               }
            })
         })
      }
   }


   //computed


   //lifecycle
   created() {
      this.initStuff();
   }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

   .stepTrash i { margin-top: 8px; cursor: pointer; }

   #page-wrap {
      max-width: 100%;

   }
   .addSteps {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 30px 0;
   }
   .theader {
       & > * {
           padding: 0;
       }
   }

   #main_recipe,
   #recipes .sequences {
       list-style: none;
       min-height: 400px;
   }
   #main_recipe > li,
   #recipes .sequences > li {
       margin-bottom: 10px;
       padding-bottom: 10px;
       border-bottom: 1px solid black;
       cursor: move;
       position: relative;
   }
   #main_recipe > li.start,
   #recipes .sequences > li.start {
       cursor: default;
   }
   #main_recipe > li.heading,
   #recipes .sequences > li.heading {
       text-align: center;
   }
   #main_recipe > li.heading div.parameter > :hover i.fa {
       opacity: 1;
   }
   #main_recipe > li.heading i.fa {
       margin: auto 1em;
       opacity: 0.1;
       cursor: pointer;
   }
   #page-wrap .ontop {
       width: 30%;
       min-width: 300px;
       margin-top: 20px;
   }
   footer {
       padding-left: 20px;
   }
   #dsequences {
       list-style: none;
       position: fixed;
       left: auto;
       top: auto;
       -webkit-transition: all 0.5s;
       -moz-transition: all 0.5s;
       -ms-transition: all 0.5s;
       -o-transition: all 0.5s;
       transition: all 0.5s;
   }
   #dsequences.scrolled {
       top: 50%;
       margin-top: -150px;
   }
   #dsequences li {
       padding: 20px;
       text-align: center;
       background-color: white;
       margin-bottom: 10px;
       border-radius: 10px;
       -webkit-border-radius: 10px;
       color: #272930;
       font-weight: bold;
       font-size: 14px;
       cursor: pointer;
       border: 2px solid #272930;
       max-width: 175px;
   }
   .ui-button-text:before {
       text-indent: 0;
       padding: 0;
       content: "x";
       position: absolute;
       top: 0;
       left: 0;
       color: #3a3832;
       text-align: center;
       width: 100%;
   }
   .ui-dialog-titlebar {
       background-color: #dd3025;
   }
   .ui-dialog {
       background-color: #3a3832;
       border-radius: 10px;
       -webkit-border-radius: 10px;
       color: white;
   }

   html body .ui-draggable-dragging {
       width: 200px !important;
   }
   html body .ui-state-disabled {
       opacity: 1.0;
   }

   /* change the URL to a picture of your choice - the higher quality the better! */
   .business-header {
       height: 250px;
       background: url("http://bkonbrew.com/wp-content/themes/bkon/img/hero3-t.jpg") center center no-repeat;
       background-size: cover;
   }

   /* adjust the color and text shadow to optimize legibility once you have an image set - you can also change the vertical positioning if desired  */
   .tagline {
       color: #fff;
       text-shadow: 0 0 10px #000;
   }

   footer {
       margin: 50px 0;
   }

   .navbar-inverse {
       background-color: #3a3832 !important;
   }

   #recipesModal table tr:hover {
       cursor: pointer;
       background-color: yellow;
   }
   #main_recipe .heading input[type=text] {
       width: 118px;
       display: inline-block;
   }
   /*change the separate portions*/
</style>

<template>
   <article class="pagewrapper">
      <section class="supportForm">
         <h2>Support Contact Information</h2>
         <p>
           <a :href="'mailto:'+contactEmail">Email: {{contactEmail}}</a>
         </p>
         <p><a href="tel:+8773793769">Customer Service: 877.379.3769</a></p>

         <BasicForm v-if="supportForm" :fields="supportFields" :model="supportForm"></BasicForm>
         <button @click="submitSupportForm()" class="btn btn-success">Send Message</button>
      </section>
      <section class="rcPosts">
         <h2>Bkon Resources</h2>
         <article class="support-flex">
           <section v-for="(post, index) in posts" :key="index">
             <a :href="post.url" target="_blank">
              <img :src="post.image" />
             </a>
             <h3>{{ post.title }}</h3>
             <hr class="solid" />
             <p>{{ post.description }}</p>
           </section>
         </article>
      </section>
   </article>
</template>

<script lang="ts">
   import {   Options, Vue } from 'vue-class-component';
   import Globals from '../Globals';
   import { API_BASE_URL } from '../environment'
   import Axios from "axios";
   import BasicForm from '../components/BasicForm.vue';

   @Options({components:{ BasicForm}})
   export default class Support extends Vue {
      //props


      //data variables
      posts:Array<any> = [];
      supportForm:any = { name:'',email:'', description:'',phone:'' };
      supportFields:Array<any> =  ['name','email','description','phone'].map((key:string)=>{
         return {
            key: key,
            placeholder:key,
            type:key =='email' ? 'email' : key == 'password' ? 'password' : key == 'phone' ? 'tel' : key =='description' ? 'textarea' : 'text',
            label:key[0].toUpperCase()+key.slice(1).replace('_',' '),
            //@ts-ignore
            model:this.supportForm[key],
            //@ts-ignore
            update(val:string) { this.supportForm[key] = val; }
         }
      });
      contactEmail:string = 'grant.gibbs@franke.com';



      //methods
      submitSupportForm() {
        let data = this.supportForm;
        Axios
          .post(`https://legacy.bkon-craftcloud.com/site/contact-form`, data, Globals.axiosConf)
          .then(({ data }) => {
             console.log(data,333);
          })
          .catch((err) => {
           console.log("Err posting ticket", err);
          });
      }

      //computed

      //lifecycle
      created() {
         Axios.get(`http://rc-cs.franke.com/site/post-by-tag/3?multi&tag&json`)
         .then(({data})=>{
            this.posts = data.slice(0,50);
         }).catch(e=>{ console.error(e);});
      }
   }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
   .pagewrapper {
      display: flex;
      padding:  30px;

      .supportForm {
         width: 30vw;
      }
      .rcPosts {
         max-width: 70vw;
         padding: 0 40px;
      }
   }

   h3 {
     margin: 8px;
   }
   ul {
     list-style-type: none;
     padding: 0;
   }
   li {
     display: inline-block;
     margin: 0 10px;
   }
   a {
     color: #42b983;
   }
   .support-flex {
     display: flex;
     justify-content: flex-start;
     flex-wrap: wrap;

     section {
       max-width: 20vw;
       margin: 8px;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
       border-radius: 8px;

       img {
         max-width: 20vw;
         border-top-left-radius: 8px;
         border-top-right-radius: 8px;
       }

       p {
         margin: 16px;
       }

       hr {
         border-top: 2px solid rgb(126, 126, 126);
         border-radius: 8px;
         margin: 8px 45%;
       }
     }
   }

   .form-flex-container {
     display: flex;
     justify-content: center;
     flex-wrap: wrap;
     margin: 8px auto;
     max-width: 25vw;
     background-color: #f2f2f2;
     padding: 5px 20px;
     border: 1px solid lightgrey;
     border-radius: 3px;

     form {
       label {
         margin-bottom: 10px;
         display: block;
       }

       input[type="text"] {
         width: 25vw;
         margin-bottom: 20px;
         padding: 12px;
         border: 1px solid #ccc;
         border-radius: 3px;
       }
       input[type="tel"] {
         width: 25vw;
         margin-bottom: 20px;
         padding: 12px;
         border: 1px solid #ccc;
         border-radius: 3px;
       }
       textarea {
         width: 25vw;
         margin-bottom: 20px;
         padding: 12px;
         border: 1px solid #ccc;
         border-radius: 3px;
       }
       .btn {
         background-color: #04aa6d;
         color: white;
         padding: 12px;
         margin: 10px 0;
         border: none;
         width: 25vw;
         border-radius: 3px;
         cursor: pointer;
         font-size: 17px;
       }
     }
   }

   @media screen and (max-width: 768px) {
     .support-flex {
       margin: 8vw;

       section {
         max-width: 30vw;

         img {
           max-width: 30vw;
         }

         p {
           margin: 16px 8px;
         }
       }
     }

     .form-flex-container {
       max-width: 55vw;

       form {
         input[type="text"] {
           width: 55vw;
         }
         input[type="tel"] {
           width: 55vw;
         }
         textarea {
           width: 55vw;
         }
         .btn {
           width: 55vw;
         }
       }
     }
   }

   @media screen and (max-width: 425px) {
     .support-flex {
       margin: 1vw;

       section {
         max-width: 85vw;

         img {
           max-width: 85vw;
         }
       }
     }
     .form-flex-container {
       max-width: 75vw;

       form {
         input[type="text"] {
           width: 75vw;
         }
         input[type="tel"] {
           width: 75vw;
         }
         textarea {
           width: 75vw;
         }
         .btn {
           width: 75vw;
         }
       }
     }
   }
</style>

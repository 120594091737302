
   import {   Options, Vue } from 'vue-class-component';
   import Globals from '../Globals';
   import { API_BASE_URL } from '../environment'
   import Axios from "axios";
   import BasicForm from '../components/BasicForm.vue';

   @Options({components:{ BasicForm}})
   export default class Support extends Vue {
      //props


      //data variables
      posts:Array<any> = [];
      supportForm:any = { name:'',email:'', description:'',phone:'' };
      supportFields:Array<any> =  ['name','email','description','phone'].map((key:string)=>{
         return {
            key: key,
            placeholder:key,
            type:key =='email' ? 'email' : key == 'password' ? 'password' : key == 'phone' ? 'tel' : key =='description' ? 'textarea' : 'text',
            label:key[0].toUpperCase()+key.slice(1).replace('_',' '),
            //@ts-ignore
            model:this.supportForm[key],
            //@ts-ignore
            update(val:string) { this.supportForm[key] = val; }
         }
      });
      contactEmail:string = 'grant.gibbs@franke.com';



      //methods
      submitSupportForm() {
        let data = this.supportForm;
        Axios
          .post(`https://legacy.bkon-craftcloud.com/site/contact-form`, data, Globals.axiosConf)
          .then(({ data }) => {
             console.log(data,333);
          })
          .catch((err) => {
           console.log("Err posting ticket", err);
          });
      }

      //computed

      //lifecycle
      created() {
         Axios.get(`http://rc-cs.franke.com/site/post-by-tag/3?multi&tag&json`)
         .then(({data})=>{
            this.posts = data.slice(0,50);
         }).catch(e=>{ console.error(e);});
      }
   }

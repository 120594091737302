<template>
  <article>
    <a href="http://rc-cs.franke.com/product/bkon" target="_blank">Resource Center</a>
  </article>
  <article>
    <h2>Recipe Imports</h2>
    <div class="filterbar">
       <div class="search-container input-group">
         <div class="input-group-addon"><i class="fa fa-search"></i></div>
         <input
           @change="runFilter()"
           v-model="searchTerm"
           type="text"
           class="form-control"
           placeholder="Search.."
           name="search"
         />
         <div class="input-group-btn"><button class="btn" type="submit">Search</button></div>
      </div>
      <select @change="runFilter()" v-model="filterAccount" class="form-control">
         <option value="0">All Accounts</option>
         <option v-for="account in accounts" :value="account.id">{{account.name}}</option>
      </select>
   </div>
  </article>
  <article class="support-flex">
     <table class="table">
        <thead>
           <tr>
             <th>Recipe Name</th>
             <th>Account</th>
          </tr>
        </thead>
        <tbody>
           <tr @click="$emit('recipeSelected',recipe)" v-for="recipe in filteredRecipes" name="list" tag="tr">
             <td class="">{{recipe.dsp_name}}</td>
             <td class="">{{accountNameForId(recipe.account)}}</td>
          </tr>
        </tbody>
     </table>
  </article>
</template>

<script lang="ts">
   import { Options, Vue } from 'vue-class-component';
   import Recipe from '../models/Recipe';
   import {Account} from '../Types';
   import Axios from 'axios';
   import { API_BASE_URL } from '../environment';
   import Globals from '../Globals';

   @Options({
      props:{
         recipes:Array
      },
      watch: {
         recipes(val) {
            this.setAccountsFromRecipes();
            this.filterRecipesBySearchValue('');
         }
      },
      emits: ['recipeSelected']
})
   export default class RecipeImportModal extends Vue {
       //props
       recipes!:Array<Recipe>;

        //data variables
        searchTerm:string = "";
        filterAccount:number = 0;
        filteredRecipes:Array<Recipe> = [];
        accounts:Array<Account> = [];

        //methods
        filterRecipesBySearchValue(searchedValue:string,recipes:Array<Recipe>|null = null) {
          if (searchedValue == "") return this.filteredRecipes = [...(recipes || this.recipes)];
          this.filteredRecipes = (recipes || this.recipes).filter((recipe:Recipe) =>recipe.dsp_name.toLowerCase().includes(searchedValue.toLowerCase())).slice(0,50);
        }
        filterByAccount(acctId:number,recipes:Array<Recipe>|null = null) {
           if(acctId == 0) return this.filteredRecipes = [...(recipes || this.recipes)];
           this.filteredRecipes = (recipes || this.recipes).filter((rec:Recipe)=>rec.account == acctId);
        }
        runFilter() {
           this.filterByAccount(this.filterAccount);
           this.filterRecipesBySearchValue(this.searchTerm,this.filteredRecipes);
        }
        setAccountsFromRecipes() {
           let accountIds:Array<number> = this.recipes.map((rec:Recipe)=>rec.account);
           Axios.get(`${API_BASE_URL}/accounts?columns=id,name&id=${accountIds.join(',,')}`,Globals.axiosConf)
           .then(({data})=>{
             this.accounts = data.map((dat:any)=>new Account(dat));
          }).catch(e=>{})
        }
        accountNameForId(acctId:number) {
           let account = this.accounts.find((acct:Account)=>acct.id == acctId);
           return account ? account.name : '( unknown )';
        }

        //computed

        //lifecycle
        created() {
           this.filterRecipesBySearchValue('');
        }


   }
</script>

<style lang="less" scoped>
   .filterbar {
      display: flex;
      justify-content: space-between;

      & > select {
         width: 40%;
      }
      & > * {
         width: 55%;
      }
   }
</style>


   import '../../public/css/google-font-opensans.css';
   import '../../public/css/google-font-raleway.css';
   import '../../public/css/recipemanager.css';
   import '../../public/css/rcu.css';
   import {
      Options,
      Vue
   } from 'vue-class-component';
   import Globals from '../Globals';
   import { API_BASE_URL } from '../environment'
   import { Menu, Account,User } from '../Types';
   import Recipe from '../models/Recipe';
   import RecipeEditor from '../components/RecipeEditor.vue';
   import RecipeList from '../components/RecipeList.vue';
   import Axios from 'axios';
   import BootstrapModal from '../components/BootstrapModal.vue';
   import RecipeImportModal from '../components/RecipeImportModal.vue';

   @Options({components:{RecipeEditor,BootstrapModal,RecipeList,RecipeImportModal}})
   export default class RecipeManager extends Vue {
      //props


      //data variables
      newRecipeFlag:boolean = false;
      currentAccountId:number = 0;
      currentStage:number = 1;
      ingredients:Array<any> = [];
      baseRecipes:Array<Recipe> = [];
      recipes:Array<Recipe> = [];
      recipePreview:number = 0;
      sharedAccounts:Array<Account> = [];
      filteredRecipes:Array<Recipe> = [];
      nameSortDir:number = 1;
      dateSortDir:number = 1;
      currentRecipe:Recipe = new Recipe;
      currentRecipeLoaded:boolean = false;
      currentRecipeChanged:boolean = false;
      currentImportRecipeSelected:number = 0;

      //methods
      openStage(stage:number) {
         //check for save
         if(!this.checkForSaving(this.currentStage)) return;

         this.currentStage = stage;
      }
      checkForSaving(currentStage:number) {
         if(currentStage == 1) { return true; }
         if(currentStage == 2) {
            if(this.currentRecipeChanged) return window.confirm('Changes UNSAVED. Are you sure you wish to proceed without saving changes?');
            else return true;
         }
         if(currentStage == 3) {
            if(this.currentRecipeChanged) return window.confirm('Changes UNSAVED. Are you sure you wish to proceed without saving changes?');
            else return true;
         }
         if(currentStage == 4) { return true; }
      }
      clearRecipe() {
        //clear out recipe specific flags
        this.currentRecipe = new Recipe;
        this.currentRecipeLoaded = false;
        this.currentRecipeChanged = false;
      }
      importRecipe(importedRecipe:Recipe):Promise<any> {
         if(!importedRecipe.id) return new Promise((resolve,reject)=>{ reject(); });
         this.currentRecipeLoaded = false;
         this.openStage(2);
         //@ts-ignore
         $('#importRecipeModal').modal('hide');
         return Axios.get(`${API_BASE_URL}/recipes/${importedRecipe.id}`,Globals.axiosConf)
         .then(({data})=>{
            this.currentRecipe = new Recipe(data);
            this.currentRecipe.account = Globals.currentAccountId;
            this.currentRecipe.id = 0;
            this.currentRecipeLoaded = true;
            this.newRecipeFlag = true;
         }).catch(e=>{})
      }
      createRecipe() {
         this.currentRecipe = new Recipe();
         this.currentRecipe.account = Globals.currentAccountId;
         this.currentRecipeLoaded = true;
         this.newRecipeFlag = true;
         this.openStage(2);
      }
      duplicateRecipe(recipe:Recipe) {
         //@todo: duplicate recipe function
         if(window.confirm('Duplicate recipe " '+recipe.dsp_name+' " ?')) alert('Sorry, duplicating a recipe is not avaiable yet.')
      }
      viewRecipe(recipe:Recipe) {
         //open panel
         //clear out recipe specific flags
         this.clearRecipe();
         this.openStage(2)

         //get full recipe from api, display meta, set current recipe
         return Axios.get(`${API_BASE_URL}/recipes/${recipe.id}`,Globals.axiosConf)
         .then(({data})=>{
            this.currentRecipe = new Recipe(data);
            this.currentRecipeLoaded = true;

            //set display name
            //@ts-ignore
            setTimeout(()=>{ document.querySelector('.recipedisplay span').innerHTML = data.dsp_name.replace(/\n/g, "<br />"); },500);

            //unit degree text @todo: set celciusmode from user settings
            //@ts-ignore
         	$("span.unit").html(window.celciusMode ? 'C' : 'F');

            //set ingredients
            setTimeout(()=>{
               //@ts-ignore
               window.Rcu.ingredientInit(this.ingredients);
            },500);

         }).catch(e=>{ console.log(e); alert('Error getting full recipe. Please refresh and try again.')})
      }
      updateRecipe(data:any) {
         if(this.currentRecipe.account != Globals.currentAccountId) return;
         Object.keys(data).forEach((key:string)=> {
            //@ts-ignore
            try { this.currentRecipe[key] = data[key]; } catch(e) { alert(' error changing recipe'); }
            this.currentRecipeChanged = true;
         });
      }
      saveCurrentRecipe() {
         if(Globals.currentAccountId != this.currentRecipe.account) return window.alert('You cannot edit a recipe that\'s not yours');
         this.currentRecipeLoaded = false;
         this.currentRecipe.modified = new Date();
         Axios[this.currentRecipe.id > 0 ? 'put' : 'post'](`${API_BASE_URL}/recipes${this.currentRecipe.id > 0 ? '/'+this.currentRecipe.id : ''}`,this.currentRecipe,Globals.axiosConf)
         .then(({data})=>{
            //reset flags
            this.currentRecipeChanged = false;
            this.currentRecipeLoaded = true;
            this.newRecipeFlag = false;
            this.currentRecipe.id = data.id;

            //update list recipe
            let lrec = this.recipes.find(rec=>rec.id == data.id);
            let flrec = this.filteredRecipes.find(rec=>rec.id == data.id);
            if(lrec) lrec.update(data);
            if(flrec) flrec.update(data);
         }).catch(e=>alert('Error saving recipe. Please refresh and try again.'))
      }
      deleteRecipe(recipe:Recipe) {
         //@todo: delete recipe function
         if(window.confirm('Are you sure you wish to delete '+recipe.dsp_name+'?' )) alert('Sorry, delete function not available yet.');
      }
      bulkDeleteRecipes() {
         //@todo: bulk delete recipe function
      }
      searchRecipes(term:string) {
         if(term.length == 0) return this.filteredRecipes = [...this.recipes];
         if(term.length > 0) {
            this.filteredRecipes = this.recipes.filter((recipe:Recipe)=>recipe.dsp_name.toLowerCase().indexOf(term.toLowerCase()) > -1 || recipe.name.toLowerCase().indexOf(term) > -1);
         }
      }
      sortByDate(order:number) {
         if(order == 0 && this.dateSortDir == 1) this.dateSortDir = -1;
         else if(order == 0 && this.dateSortDir != 1) this.dateSortDir = 1;
         order = order == 0 ? this.dateSortDir : order;
         console.log('date',order);
         this.filteredRecipes = this.filteredRecipes.sort((a,b)=>new Date(a.modified).getTime() > new Date(b.modified).getTime() ? order : order * -1);
      }
      sortByName(order:number) {
         if(order == 0 && this.nameSortDir == 1) this.nameSortDir = -1;
         else if(order == 0 && this.nameSortDir != 1) this.nameSortDir = 1;
         order = order == 0 ? this.nameSortDir : order;
         console.log('name',order);
         this.filteredRecipes = this.filteredRecipes.sort((a,b)=>a.dsp_name.toLowerCase() > b.dsp_name.toLowerCase() ? order : order * -1);
      }
      filterByType(type:number) {
         if(!type) return this.filteredRecipes = [...this.recipes];
         this.filteredRecipes = this.recipes.filter((recipe:Recipe)=>recipe.type == type);
      }
      filterByAccount(accountId:number) {
         if(!accountId) return this.filteredRecipes = [...this.recipes];
         this.filteredRecipes = this.recipes.filter((recipe:Recipe)=>recipe.account == accountId);
      }
      filterIngredients() {
         //@ts-ignore
         window.Rcu.filterIngredients()
      }

      //computed
      get RECIPE_TYPES() { return Recipe.types; }
      get importRecipes() { return this.recipes.filter((rec:Recipe)=>rec.import); }


      //lifecycle
      async created() {
         await Globals.getMe();

         this.currentAccountId = Globals.currentAccountId;

         //get recipes
         Axios.get(`${API_BASE_URL}/recipes/shared?&columns=id,name,type,dsp_name,modified,account,account.import`,Globals.axiosConf)
         .then(({data})=>{
            this.recipes = data.map((rec:any)=>new Recipe(rec));
            this.baseRecipes = data.filter((rec:any)=>rec.import).map((rec:any)=>new Recipe(rec));
            this.filteredRecipes = [...this.recipes];
         }).catch(e=>{ console.error(e); });

         //get ingredients
         Axios.get(`${API_BASE_URL}/ingredients?account=0,,${Globals.currentAccountId}`,Globals.axiosConf)
         .then(({data})=>{
            // this.ingredients = data;
            //@ts-ignore
            window.ingjs = data.map(ing=>{ ing.category = ing.category.toLowerCase(); return ing;}).toObjectWithKeyAndArray('category');
         }).catch(e=>{});

         if(this.$route.params && this.$route.params.id) {
            let tmpRec = new Recipe({id:this.$route.params.id})
            this.viewRecipe(tmpRec)
            .then(()=>{
               this.openStage(3);
            }).catch(e=>{})
         }
      }
      mounted() {
         let $this = this;

        //ingredientAdd
        //@ts-ignore
        $(document).on('change','#ingredientAdd',function(e) {
           //@ts-ignore
            var cur = $(this);
            //@ts-ignore
            if($(this).val().length > 2) {
                var notIn = true;
                //@ts-ignore
                $.each(window.Rcu.ingredients,function(ind,val) {
                    if(val.name == cur.val()) {
                        notIn = false;
                    }
                });
                if(notIn) {
                   //@ts-ignore
                    $this.currentRecipe.ingredients.push({name:$(this).val(),small:0,medium:0,large:0,text:''});
                    $this.updateRecipe({ingredientsPopup: 1,ingredients:$this.currentRecipe.ingredients});
                }
            }
        });



        //ingredientsChangeUnits
        //@ts-ignore
        $(document).on('change','.ingredients select.units',function(e) {
           //@ts-ignore
            $(this).parent().children('.ingredientAmount').trigger('change');
        });

         //barista talking points enforcement
         //@ts-ignore
         $(document).on('keydown','textarea#Recipe_description',window.talkingpoints);

        //display name enforcement
        //@ts-ignore
        $(document).on('keydown','textarea#Recipe_dsp_name',window.DspName);
        //@ts-ignore
        $(document).on('keyup','textarea#Recipe_dsp_name',function(e) {
           //@ts-ignore
           $('.recipedisplay span').html($(this).val().replace(/\n/g, "<br />"));
        });
      }

   }

<template>
   <div class="pagewrapper">
      <h2>User Settings</h2>
      <div v-show="loading" style="text-align: center;"><img src="/images/loading.svg " /></div>
      <div v-show="!loading">
         <BasicForm  v-if="currentUser.id > 0" :fields="userFields" :model="currentUser"></BasicForm>
         <button @click="saveUser()" class="btn btn-primary"><i class="fa fa-save"></i> Update Settings</button>
         <br /><br />
         <a :href="'https://legacy.bkon-craftcloud.com/user/u/'+currentUser.id+'?apiKey='+userToken" target="_blank">Need More? Change Advanced Settings here</a>
      </div>
   </div>
</template>

<script lang="ts">
   import { Options, Vue } from 'vue-class-component';
   import Globals from '../Globals';
   import { API_BASE_URL } from '../environment'
   import { Account,User } from '../Types';
   import Axios from 'axios';
   // import BootstrapModal from '../components/BootstrapModal.vue';
   import BasicForm from '../components/BasicForm.vue'

   const unitsOptions = [{value:0,text:'Imperial'},{value:1,text:'Metric'}];
   const languagesOptions = [{value:1,text:'English'},{value:3,text:'Chinese'},{value:5,text:'Deutsh'},{value:7,text:'Turkish'},{value:8,text:'South Korean'}];


   @Options({components:{BasicForm}})
   export default class UserManagement extends Vue {
       //props


        //data variables
        loading:boolean = true;
        currentUser:User = new User();
        userFields:Array<any> = [];
        userToken:string = '';

        //methods
        saveUser() {
          let userData:any = { };
          this.userFields.forEach((userField:any)=>{
             //@ts-ignore
             userData[userField.key] = this.currentUser[userField.key];
          });
          this.loading = true;
          Axios.put(`${API_BASE_URL}/users/${this.currentUser.id}`,userData,Globals.axiosConf)
          .then(({data})=>{
            this.loading = false;
          }).catch(e=>alert('user not saved'));
        }
        setUserFields() {
           let $this = this;
           this.userFields = ['name','email','password','primaryLanguage','primaryUnits'].map((key:string)=>{
                 return {
                    key: key,
                    placeholder:key,
                    type:key =='email' ? 'email' : key == 'password' ? 'password' : ['primaryUnits','primaryLanguage'].indexOf(key) > -1 ? 'select' : 'text',
                    label:key[0].toUpperCase()+key.slice(1).replace('_',' '),
                    options:key == 'primaryUnits' ? unitsOptions : key == 'primaryLanguage' ? languagesOptions : null,
                    //@ts-ignore
                    model:this.currentUser[key],
                    //@ts-ignore
                    update(val:string) { $this.currentUser[key] = val; }

                 }
             });
        }


        //computed

        //lifecycle
        async created() {
            await Globals.getMe();
            this.currentUser = Globals.me;
            this.setUserFields();
            this.loading = false;
            this.userToken = Globals.me.auth_key;
        }
   }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
   .pagewrapper {
      max-width: 1200px;
      margin: auto;
   }
</style>

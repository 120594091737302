<template>
   <div class="pagewrapper" id="recipemanager">
      <header>
         <h2 class="menuBuilderHeading">Menu Manager</h2>
         <p class="rm_msg">Select a Menu to modify or Create a new one. Then drag add recipes into the desired category.</p>
      </header>
      <section :class="'stages stage_open_'+currentStage">
         <div class="menus details stage1 stage" :class="{currentStage:currentStage == 1,pastStage:currentStage > 1,currentStages:currentStage >= 1 }">
            <div class="menus">
               <div id="menumanager_listview" class="list-view">
                  <div class="menulist">
                      <div class="searchAddContainer">
                         <div class="searchSection pull-left">
                            <div class="input-group">
                                <input @keyup="searchMenus($event.target.value)" type="text" class="form-control" placeholder="Search Menus" value="">
                                <span class="input-group-btn"><button class="btn btn-primary">Search</button></span>
                            </div>
                        </div>
                        <div class="addSection pull-right">
                            <button @click="createMenu()" class="btn btn-primary">Create</button>
                        </div>
                        <p class="clearfix"></p>
                     </div>
                     <div class="summary">Displaying 1 - {{filteredMenus.length}} of {{filteredMenus.length}} menus</div>
                     <div class="items ">
                       <div v-for="(menu,menuIndex) in filteredMenus" style="position: relative" :data-id="menu.id" :class="{external: currentAccountId != menu.account}" class="item  ">
                        <button data-toggle="dropdown" class="pull-right btn-sm btn dropdown-toggle" style="background: none; border: none; box-shadow: none;"><i class="fa fa-cog"></i></button>
                        <ul class="dropdown-menu" style="top: auto; right: 0px; width: auto; left: auto;">
                           <li><a @click="duplicateMenu(menu)" href="#" class="">Duplicate</a></li>
                           <li><a @click="saveCurrentMenu(); compileMenu(menu)" href="#" class="">Compile</a></li>
                           <li><a @click="deleteMenu(menu)" class="" id="" href="#">Delete</a></li>
                        </ul>

                        <h4 @click="viewMenu(menu)">{{menu.description}}</h4>
                        <p @click="viewMenu(menu)">Last updated: {{new Date(menu.date).simpleDay()}}</p>
                        <p @click="viewMenu(menu)">Holding {{menu.recipeCount}} categories</p>
                      </div>
                     </div>
                  </div>

               </div>
            </div>
         </div>
         <div class="stage stage2 menus details" :class="{currentStage:currentStage == 2,pastStage:currentStage > 2,currentStages:currentStage >= 2 }">
            <div class="stage-actions">
               <button @click="openStage(1)" class="btn"><i class="fa fa-chevron-left"></i> Back</button>
               <!-- <button @click="openStage(currentStage + 1)" class="btn pull-right">Recipe Steps <i class="fa fa-chevron-right"></i></button> -->
               <button v-show="currentStage == 2 && currentMenuChanged" @click="saveCurrentMenu()" class=" btn pull-right btn-primary" style="color: white;">Save Menu</button>
            </div>
            <div v-show="!currentMenuLoaded">
               <img src="/images/loading.svg " />
            </div>
            <div v-if="currentMenu && currentMenuLoaded" class="menuDetails">
               <div class="meta">
                  <div class="mainDetails">
                     <input @change="currentMenuChanged = true" v-model="currentMenu.description" type="text" class="menuName form-control" placeholder="Menu Title">
                     <p>Select a Category to modify. Then add your recipes into the desired position.</p>
                  </div>
                  <div class="ui">
                     <div class="categories">
                        <div @click="viewCategory(catIndex)" v-for="(category,catIndex) in currentMenu.recipes" class="name" :class="{recipeDisabled:category.name.length < 1}" :data-name="category.name" :data-id="catIndex" :style="'border-left-color:'+category.color">
                           {{category.name || '( Click to add )'}}
                        </div>
                     </div>
                  </div>
                  <button @click="saveCurrentMenu()" class="saveMenu btn pull-right btn-primary">Save</button>
                  <button @click="compileMenu(currentMenu)" class=" btn btn-default pull-right expbtn" data-content="" data-template="<div class='popover'><div class='arrow'></div><h3 class='popover-title'></h3><div class='popover-content'></div><a href='/compile/u/2425?compile=makemenufile&amp;version=7' class='btn-primary btn-block btn' target='_blank'>Version 7</a><a href='/compile/u/2425?compile=makemenufile&amp;version=8' class='btn-primary btn-block btn' target='_blank'>Version 8</a></div>" data-original-title="Compile Options">Compile</button>
                  <button @click="updateRecipesInMenu(menu)" class="updateThisMenu btn btn-default pull-right">Sync Recipes</button>
                  <!-- <button class="lookinto btn btn-default pull-right">Recipes</button> -->
               </div>
            </div>
         </div>
         <div class="stage stage3 menus details" :class="{currentStage:currentStage == 3,pastStage:currentStage > 3,currentStages:currentStage >= 3 }">
            <div class="stage-actions">
               <button @click="openStage(currentStage - 1)" class="btn"><i class="fa fa-chevron-left"></i> Back</button>
               <!-- <button @click="openStage(currentStage + 1)" class="btn pull-right">Recipes <i class="fa fa-chevron-right"></i></button> -->
               <button v-show="currentStage == 3 && currentMenuChanged" @click="saveCurrentMenu()" class=" btn pull-right btn-primary" style="color: white;">Save Menu</button>
            </div>
            <div v-if="currentCategoryIndex > -1" class="menuDetails inRecipe">
               <div class="meta">
                  <div class="categoryDetails">
                     <div class="info" data-name="Green Tea" data-id="0" style="display: block;">
                        <div class="input-group">
                           <span class="input-group-addon" style="position: relative">
                              <input class="catColor" placeholder="" :style="'display: none; background-color: '+currentMenu.recipes[currentCategoryIndex].color" :data-value="currentMenu.recipes[currentCategoryIndex].color" type="color">
                           </span>
                           <textarea
                                 @change="updateMenu({recipes: currentMenu.recipes})"
                                 v-model="currentMenu.recipes[currentCategoryIndex].name"
                                 class="form-control curCatName" placeholder="( Click to add )"></textarea>
                        </div>
                        <p>Add recipes from your library into the desired position.</p>
                     </div>
                  </div>
               </div>
               <div class="ui">
                  <div class="recipes">
                     <div class="category">
                        <div v-for="recipeIndex in recipeIndexesForPage(currentCategoryPage)" style="margin-bottom: 0px; border: none;" class="textAreaFocus form-group descP" :data-id="currentMenu.recipes[currentCategoryIndex].recipes[recipeIndex] ? currentMenu.recipes[currentCategoryIndex].recipes[recipeIndex].id : -1">
                           <textarea
                           v-if="currentMenu.recipes[currentCategoryIndex].recipes[recipeIndex]"
                           @change="updateMenu({recipes:currentMenu.recipes})" v-model="currentMenu.recipes[currentCategoryIndex].recipes[recipeIndex].description"
                           name="myname"
                           style="height: auto; width: auto; resize: none; border: thin solid gray; color: white; background-color: rgba(0,0,0,0.85); overflow: hidden; margin: auto"
                           cols="35" rows="8" wrap="hard" maxlength="255" class="form-control desc"></textarea>
                        </div>
                        <div class="recipe_chunk">
                           <div v-for="recipeIndex in recipeIndexesForPage(currentCategoryPage)"
                                 @click="currentMenu.recipes[currentCategoryIndex].recipes[recipeIndex].dsp_name == 'Empty' ? addRecipe(recipeIndex) : null"
                                 :class="{recipeDisabled:currentMenu.recipes[currentCategoryIndex].recipes[recipeIndex].dsp_name == 'Empty'}"
                                 :style="'border-left-color: ' + currentMenu.recipes[currentCategoryIndex].color"
                                 class="recipe">
                              <button @click="removeRecipeAtCategoryAndIndex(currentCategoryIndex,recipeIndex,$event)" class="closeIt" data-title="Remove Recipe" data-toggle="tooltip" data-placement="right" title="Remove recipe">x</button>
                              <i @click="showDescription($event,currentMenu.recipes[currentCategoryIndex].recipes[recipeIndex].id)" class="editIt pull-right fa fa-pencil" title="Barista Talking Points"></i>
                              <textarea
                                    v-show="!isRecipeEmpty(recipeIndex)"
                                    @change="updateMenu({recipes:currentMenu.recipes})"
                                    v-model="currentMenu.recipes[currentCategoryIndex].recipes[recipeIndex].dsp_name"
                                    :disabled="currentMenu.recipes[currentCategoryIndex].recipes[recipeIndex].dsp_name == 'Empty'"
                                    class="form-control" style=""></textarea>
                              <div
                                 v-show="isRecipeEmpty(recipeIndex)"
                                 class="dspName">{{ currentMenu.recipes[currentCategoryIndex].recipes[recipeIndex].dsp_name }}</div>
                              
                           </div>
                        </div>
                     </div>
                     <div class="pagination">
                         <button @click="currentCategoryPage = 1" class="btn 1" :class="{'btn-info':currentCategoryPage == 1 }">Ⅰ</button>
                         <button @click="currentCategoryPage = 2" class="btn 2" :class="{'btn-info':currentCategoryPage == 2 }">Ⅱ</button>
                         <button @click="currentCategoryPage = 3" class="btn 3" :class="{'btn-info':currentCategoryPage == 3 }">Ⅲ</button>
                         <button @click="currentCategoryPage = 4" class="btn 4" :class="{'btn-info':currentCategoryPage == 4 }">Ⅳ</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="stage stage4">
            <div class="stage-actions">
               <button @click="openStage(currentStage - 1)" class="btn"><i class="fa fa-chevron-left"></i> Back</button>
               <!-- <button @click="openStage(currentStage + 1)" class="btn pull-right">Recipe Steps <i class="fa fa-chevron-right"></i></button> -->
            </div>

            <RecipeList @recipeSelected="replaceRecipeAtIndex(currentRecipeIndex,$event)" :recipes="recipes" :currentAccount="currentAccount"></RecipeList>
         </div>
      </section>
   </div>
</template>

<script lang="ts">
   import '../../public/css/google-font-opensans.css';
   import '../../public/css/google-font-raleway.css';
   import '../../public/css/recipemanager.css';
   import '../../public/css/spectrum.css';
   import {   Options, Vue } from 'vue-class-component';
   import Globals from '../Globals';
   import { API_BASE_URL } from '../environment'
   import { Menu, Account,User } from '../Types';
   import Recipe from '../models/Recipe';
   import RecipeList from '../components/RecipeList.vue';
   import MenuBuilder from '../components/MenuBuilder.vue';
   import Axios from 'axios';
   import BootstrapModal from '../components/BootstrapModal.vue';

   @Options({components:{MenuBuilder,BootstrapModal,RecipeList}})

   export default class MenuManager extends Vue {
    //props


     //data variables
     recipes:Array<Recipe> = [];
     currentStage:number = 1;
     menus:Array<Menu> = [];
     filteredMenus:Array<Menu> = [];
     currentMenu:Menu = new Menu;
     currentMenuLoaded:boolean = false;
     currentMenuChanged:boolean = false;
     currentCategoryIndex:number = -1;
     currentCategoryPage:number = 1;
     currentRecipeIndex:number = -1;
     currentAccountId:number = 0;

     //methods
     openStage(stage:number) {
        if(this.currentMenuChanged && this.currentStage != 4 && this.currentStage != 3 && !(this.currentStage == 2 && stage == 3) && !window.confirm('You have unsaved work. Are you sure you wish to continue without saving?')) return;
        this.currentStage = stage;
     }
     searchMenus(term:string) {
        if(term.length == 0) return this.filteredMenus = [...this.menus];
        this.filteredMenus = this.menus.filter((menu:Menu)=>menu.description.toLowerCase().indexOf(term.toLowerCase())>-1)
     }
     cleanMenuForExport(menu:Menu) {
        //clean up empty slots to not be present
        menu.recipes = menu.recipes.filter((category:any)=>category.name.length > 0);

        //prepare data
        menu.recipes.forEach((category:any)=>{
           //get color and ensure it has # sign

           //check celcium mode to process category name
           //@ts-ignore
           category.name = window.celciusMode ? window.dspNameFar(category.name) : category.name;

           category.recipes.forEach((recipe:any)=>{
             //check celcius mode to process recipe name
             //@ts-ignore
             recipe.name = window.celciusMode ? window.dspNameFar(recipe.name) : recipe.name;
             //@ts-ignore
             recipe.dsp_name = window.celciusMode ? window.dspNameFar(recipe.dsp_name) : recipe.dsp_name;
          })

        })
     }
     clearCurrentMenu() {
       this.currentMenuLoaded = false;
       this.currentMenuChanged = false;
       this.currentMenu = new Menu;
       this.currentCategoryIndex = -1;
       this.currentCategoryPage = 1;
       this.currentRecipeIndex = -1;
       this.currentAccountId = 0;
     }
     saveCurrentMenu() {
        this.currentMenuLoaded = false;
        this.currentMenuChanged = false;
        this.currentCategoryIndex = -1;
        this.currentCategoryPage = 1;
        this.cleanMenuForExport(this.currentMenu);
        this.openStage(2);


       //save to api
       Axios[this.currentMenu.id > 0 ? 'put' : 'post'](`${API_BASE_URL}/compiles${this.currentMenu.id > 0 ? '/'+this.currentMenu.id : ''}`,this.currentMenu,Globals.axiosConf)
       .then(({data})=>{
          this.currentMenuLoaded = true;

       })
       .catch(e=>{})
     }
     updateRecipesInMenu(menu:Menu) {

     }
     updateMenu(data:any) {
        this.currentMenuChanged = true;
        // debugger;
     }
     createMenu() {
        this.viewMenu(new Menu({account:Globals.currentAccountId}));
     }
     viewMenu(menu:Menu) {
       this.clearCurrentMenu();
        this.openStage(2);
        let setRecipes = () => {
           for(let a = 0; a < 8; a++) {
             if(!this.currentMenu.recipes[a]) this.currentMenu.recipes[a] = {name:'',color:'',recipes:[]}
             for(let b= 0;b<32;b++) {
                if(!this.currentMenu.recipes[a].recipes[b]) this.currentMenu.recipes[a].recipes[b] = {id:-1,name:'Empty',dsp_name:'Empty',description:'',sequences:'[]'};
             }
            }
         }

        if(menu.id < 1) {
           return new Promise((resolve,reject)=>{
             this.currentMenu = menu;
             setRecipes();
             this.currentMenuLoaded = true;
             resolve(this.currentMenu);
          });
         }

        return Axios.get(`${API_BASE_URL}/compiles/${menu.id}`,Globals.axiosConf)
        .then(({data})=>{
           this.currentMenuLoaded = true;
           this.currentMenu = new Menu(data);
           setRecipes();
        })
     }
     viewCategory(categoryIndex:number) {
        let $this = this;
        this.currentCategoryIndex = categoryIndex;
        this.openStage(3);

        //initialize spectrum jquery lib
        setTimeout(()=>{
           //@ts-ignore
           $('input.catColor').spectrum({
              replacerClassName: 'checkit',
              clickoutFiresChange: true,
              color: this.currentMenu.recipes[categoryIndex].color,
              showPalette: true,
              move: function(color:any) {
                  $this.currentMenu.recipes[categoryIndex].color = color.toString();
                  $this.updateMenu({recipes:$this.currentMenu.recipes});
              },
              palette: [
                   ['black', 'white', 'blanchedalmond',
                   'rgb(255, 128, 0);', 'hsv 100 70 50'],
                   ['red', 'yellow', 'green', 'blue', 'violet']
              ]
           });
        },500);

     }
     deleteMenu(menu:Menu) {

     }
     duplicateMenu(menu:Menu) {
        let newMenu:Menu = { ...menu };
        this.viewMenu(newMenu)
        .then(()=>{
           this.currentMenu.description += " ( copy )";
           this.currentMenu.account = Globals.currentAccountId;
           this.currentMenu.id = 0;
        }).catch(e=>console.log(e,111));
     }
     compileMenu(menu:Menu) {
        let menuName:string = window.prompt("Do you wish to name the menu that's downloaded?") || '';
        let compileAddr:string = `https://legacy.bkon-craftcloud.com/compile/update/${menu.id}?compile=makemenufile&version=15&menuFileType=2&ingredients=1${menuName.length > 0 ?'&customName='+menuName : ''}`;

        this.cleanMenuForExport(menu);

        //prepare form data for xxx-formurlencoded
        // let formd = new FormData();
        // formd.append('menudump',JSON.stringify(menu.recipes));
        // Axios.post(compileAddr,formd,Globals.axiosConf)
        // .then(({data})=>{
        //
        // }).catch(e=>console.error(e,9999));

        let md = document.createElement('input');
        md.setAttribute('type','hidden');
        md.setAttribute('value',JSON.stringify(menu.recipes));
        md.setAttribute('name','menudump');
        let form = document.createElement('form');
        form.setAttribute('action',compileAddr);
        form.setAttribute('method','post');
        form.setAttribute('target','_blank');
        form.style.display = 'none';
        form.append(md);
        document.body.append(form);
        form.submit();

        setTimeout(()=>{ form.remove() },30000);
     }
     removeRecipeAtCategoryAndIndex(categoryIndex:number,recipeIndex:number, evt:any) {
         if(evt) { evt.stopPropagation(); evt.preventDefault(); }
         if (!window.confirm("Are you sure you want to remove " + this.currentMenu.recipes[categoryIndex].recipes[recipeIndex].dsp_name + " from this category?")) return;
        this.currentMenu.recipes[categoryIndex].recipes[recipeIndex] = {
            dsp_name: 'Empty',
            name:'Empty',
           id: -1
        }
        this.updateMenu({recipes:this.currentMenu.recipes});
     }
     recipeIndexesForPage(pageNumber:number) {
        if(pageNumber == 1) return [0,1,2,3,4,5,6,7];
        if(pageNumber == 2) return [8,9,10,11,12,13,14,15];
        if(pageNumber == 3) return [16,17,18,19,20,21,22,23];
        if(pageNumber == 4) return [24,25,26,27,28,29,30,31];
     }
     showDescription(evt:any,id:number) {
         if(evt) { evt.stopPropagation(); evt.preventDefault(); }
        let descEl:HTMLElement|null = document.querySelector('.descP[data-id="'+id+'"]');
        if(descEl) descEl.style.display = 'block';
        let descTextEl:HTMLElement|null = document.querySelector('.descP[data-id="'+id+'"] textarea');
        if(descTextEl) descTextEl.focus();
     }
     addRecipe(recipeIndex:number) {
        this.currentRecipeIndex = recipeIndex;
        this.openStage(4);
        //get recipes
        Axios.get(`${API_BASE_URL}/recipes/shared?columns=id,name,type,dsp_name,modified,account`,Globals.axiosConf)
        .then(({data})=>{
           this.recipes = data.map((rec:any)=>new Recipe(rec));
        }).catch(e=>{ console.error(e); });
     }
     replaceRecipeAtIndex(recipeIndex:number,newRecipe:Recipe) {
        Axios.get(`${API_BASE_URL}/recipes/${newRecipe.id}`,Globals.axiosConf)
        .then(({data})=>{
           this.currentRecipeIndex = -1;
           data.sequences = JSON.stringify(data.sequences);
           this.currentMenu.recipes[this.currentCategoryIndex].recipes[recipeIndex] = data;
           this.updateMenu({recipes:this.currentMenu.recipes});
           this.openStage(3);
        }).catch(e=>{ });
     }
     displayNameClicked(recipeIndex:number) {
         if (this.isRecipeEmpty(recipeIndex)) {
            this.addRecipe(recipeIndex);
         }
     }
     isRecipeEmpty(recipeIndex:number) {
         return this.currentMenu.recipes[this.currentCategoryIndex].recipes[recipeIndex].dsp_name == 'Empty';
     }

     //computed
     get currentAccount() { return Globals.currentAccountId; }


     //lifecycle
     async created() {
        await Globals.getMe();
        this.currentAccountId = Globals.currentAccountId;
        //get recipes
        Axios.get(`${API_BASE_URL}/compiles/shared?columns=id,account,description,date,JSON_LENGTH(recipes) as recipeCount`,Globals.axiosConf)
        .then(({data})=>{
           this.menus = data.map((men:any)=>new Menu(men)).sort((a:Menu,b:Menu)=>new Date(a.date).getTime() < new Date(b.date).getTime() ? 1 : -1);
           this.filteredMenus = [...this.menus];
        }).catch(e=>{ console.error(e); });

        if(this.$route.params && this.$route.params.id) {
           let tmpMen = new Menu({id:this.$route.params.id})
           this.viewMenu(tmpMen)
           .then(()=>{
             this.openStage(2);
           }).catch(e=>{})
        }
     }
     mounted() {
        //@ts-ignore
        $('.atooltip').tooltip();

        //@ts-ignore
        $(document).on('blur','.textAreaFocus',function(e) {
           //@ts-ignore
           $(this).hide();
        })
     }

   }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
   html {
      body {

         .pagewrapper {
            padding: 0 60px;

            .summary {
               margin: 10px 0 0;
            }

            .items {

               .item {
                  margin: 20px 0;

                  h4 { cursor: pointer; }
               }
            }
            .meta { background-color: transparent !important; }
            .ui {
               min-height: 400px;

               .category {
                  min-height: 400px;
               }
            }
         }

         .stages {
               display: flex;
               .stage-actions {
                  padding: 15px 0;


               }

               .stage2 .stage-actions button, .stage3 .stage-actions button { color: black; }

               .stage1, .stage2, .stage3, .stage4 {
                  max-width: 0px;
                  flex: 1;
                  transition: .5s linear all;
                  opacity: 0;
                  padding: 0 15px;
                  margin: 0 15px;
               }
               .pastStage {
                  opacity: 0.3;
               }
               .currentStage { z-index: 999; }
               &.stage_open_1 {
                  .stage1 {
                     max-width: 700px;
                     opacity: 1;
                  }
               }
               &.stage_open_2 {

                  .stage1 {
                     max-width: 40%
                  }
                  .stage2 {
                     max-width: 500px;
                     opacity: 1;
                  }

               }
               &.stage_open_3 {

                  .stage1,.stage2 {
                     max-width: 25%
                  }
                  .stage3 {
                     max-width: 500px;
                     opacity: 1;
                  }
               }
               &.stage_open_4 {

                  .stage1,.stage2,.stage3 {
                     max-width: 17%
                  }
                  .stage4 {
                     max-width: 40%;
                     opacity: 1;
                  }

               }

         }
      }
   }

</style>


   import '../../public/css/google-font-opensans.css';
   import '../../public/css/google-font-raleway.css';
   import '../../public/css/recipemanager.css';
   import '../../public/css/spectrum.css';
   import {   Options, Vue } from 'vue-class-component';
   import Globals from '../Globals';
   import { API_BASE_URL } from '../environment'
   import { Menu, Account,User } from '../Types';
   import Recipe from '../models/Recipe';
   import RecipeList from '../components/RecipeList.vue';
   import MenuBuilder from '../components/MenuBuilder.vue';
   import Axios from 'axios';
   import BootstrapModal from '../components/BootstrapModal.vue';

   @Options({components:{MenuBuilder,BootstrapModal,RecipeList}})

   export default class MenuManager extends Vue {
    //props


     //data variables
     recipes:Array<Recipe> = [];
     currentStage:number = 1;
     menus:Array<Menu> = [];
     filteredMenus:Array<Menu> = [];
     currentMenu:Menu = new Menu;
     currentMenuLoaded:boolean = false;
     currentMenuChanged:boolean = false;
     currentCategoryIndex:number = -1;
     currentCategoryPage:number = 1;
     currentRecipeIndex:number = -1;
     currentAccountId:number = 0;

     //methods
     openStage(stage:number) {
        if(this.currentMenuChanged && this.currentStage != 4 && this.currentStage != 3 && !(this.currentStage == 2 && stage == 3) && !window.confirm('You have unsaved work. Are you sure you wish to continue without saving?')) return;
        this.currentStage = stage;
     }
     searchMenus(term:string) {
        if(term.length == 0) return this.filteredMenus = [...this.menus];
        this.filteredMenus = this.menus.filter((menu:Menu)=>menu.description.toLowerCase().indexOf(term.toLowerCase())>-1)
     }
     cleanMenuForExport(menu:Menu) {
        //clean up empty slots to not be present
        menu.recipes = menu.recipes.filter((category:any)=>category.name.length > 0);

        //prepare data
        menu.recipes.forEach((category:any)=>{
           //get color and ensure it has # sign

           //check celcium mode to process category name
           //@ts-ignore
           category.name = window.celciusMode ? window.dspNameFar(category.name) : category.name;

           category.recipes.forEach((recipe:any)=>{
             //check celcius mode to process recipe name
             //@ts-ignore
             recipe.name = window.celciusMode ? window.dspNameFar(recipe.name) : recipe.name;
             //@ts-ignore
             recipe.dsp_name = window.celciusMode ? window.dspNameFar(recipe.dsp_name) : recipe.dsp_name;
          })

        })
     }
     clearCurrentMenu() {
       this.currentMenuLoaded = false;
       this.currentMenuChanged = false;
       this.currentMenu = new Menu;
       this.currentCategoryIndex = -1;
       this.currentCategoryPage = 1;
       this.currentRecipeIndex = -1;
       this.currentAccountId = 0;
     }
     saveCurrentMenu() {
        this.currentMenuLoaded = false;
        this.currentMenuChanged = false;
        this.currentCategoryIndex = -1;
        this.currentCategoryPage = 1;
        this.cleanMenuForExport(this.currentMenu);
        this.openStage(2);


       //save to api
       Axios[this.currentMenu.id > 0 ? 'put' : 'post'](`${API_BASE_URL}/compiles${this.currentMenu.id > 0 ? '/'+this.currentMenu.id : ''}`,this.currentMenu,Globals.axiosConf)
       .then(({data})=>{
          this.currentMenuLoaded = true;

       })
       .catch(e=>{})
     }
     updateRecipesInMenu(menu:Menu) {

     }
     updateMenu(data:any) {
        this.currentMenuChanged = true;
        // debugger;
     }
     createMenu() {
        this.viewMenu(new Menu({account:Globals.currentAccountId}));
     }
     viewMenu(menu:Menu) {
       this.clearCurrentMenu();
        this.openStage(2);
        let setRecipes = () => {
           for(let a = 0; a < 8; a++) {
             if(!this.currentMenu.recipes[a]) this.currentMenu.recipes[a] = {name:'',color:'',recipes:[]}
             for(let b= 0;b<32;b++) {
                if(!this.currentMenu.recipes[a].recipes[b]) this.currentMenu.recipes[a].recipes[b] = {id:-1,name:'Empty',dsp_name:'Empty',description:'',sequences:'[]'};
             }
            }
         }

        if(menu.id < 1) {
           return new Promise((resolve,reject)=>{
             this.currentMenu = menu;
             setRecipes();
             this.currentMenuLoaded = true;
             resolve(this.currentMenu);
          });
         }

        return Axios.get(`${API_BASE_URL}/compiles/${menu.id}`,Globals.axiosConf)
        .then(({data})=>{
           this.currentMenuLoaded = true;
           this.currentMenu = new Menu(data);
           setRecipes();
        })
     }
     viewCategory(categoryIndex:number) {
        let $this = this;
        this.currentCategoryIndex = categoryIndex;
        this.openStage(3);

        //initialize spectrum jquery lib
        setTimeout(()=>{
           //@ts-ignore
           $('input.catColor').spectrum({
              replacerClassName: 'checkit',
              clickoutFiresChange: true,
              color: this.currentMenu.recipes[categoryIndex].color,
              showPalette: true,
              move: function(color:any) {
                  $this.currentMenu.recipes[categoryIndex].color = color.toString();
                  $this.updateMenu({recipes:$this.currentMenu.recipes});
              },
              palette: [
                   ['black', 'white', 'blanchedalmond',
                   'rgb(255, 128, 0);', 'hsv 100 70 50'],
                   ['red', 'yellow', 'green', 'blue', 'violet']
              ]
           });
        },500);

     }
     deleteMenu(menu:Menu) {

     }
     duplicateMenu(menu:Menu) {
        let newMenu:Menu = { ...menu };
        this.viewMenu(newMenu)
        .then(()=>{
           this.currentMenu.description += " ( copy )";
           this.currentMenu.account = Globals.currentAccountId;
           this.currentMenu.id = 0;
        }).catch(e=>console.log(e,111));
     }
     compileMenu(menu:Menu) {
        let menuName:string = window.prompt("Do you wish to name the menu that's downloaded?") || '';
        let compileAddr:string = `https://legacy.bkon-craftcloud.com/compile/update/${menu.id}?compile=makemenufile&version=15&menuFileType=2&ingredients=1${menuName.length > 0 ?'&customName='+menuName : ''}`;

        this.cleanMenuForExport(menu);

        //prepare form data for xxx-formurlencoded
        // let formd = new FormData();
        // formd.append('menudump',JSON.stringify(menu.recipes));
        // Axios.post(compileAddr,formd,Globals.axiosConf)
        // .then(({data})=>{
        //
        // }).catch(e=>console.error(e,9999));

        let md = document.createElement('input');
        md.setAttribute('type','hidden');
        md.setAttribute('value',JSON.stringify(menu.recipes));
        md.setAttribute('name','menudump');
        let form = document.createElement('form');
        form.setAttribute('action',compileAddr);
        form.setAttribute('method','post');
        form.setAttribute('target','_blank');
        form.style.display = 'none';
        form.append(md);
        document.body.append(form);
        form.submit();

        setTimeout(()=>{ form.remove() },30000);
     }
     removeRecipeAtCategoryAndIndex(categoryIndex:number,recipeIndex:number, evt:any) {
         if(evt) { evt.stopPropagation(); evt.preventDefault(); }
         if (!window.confirm("Are you sure you want to remove " + this.currentMenu.recipes[categoryIndex].recipes[recipeIndex].dsp_name + " from this category?")) return;
        this.currentMenu.recipes[categoryIndex].recipes[recipeIndex] = {
            dsp_name: 'Empty',
            name:'Empty',
           id: -1
        }
        this.updateMenu({recipes:this.currentMenu.recipes});
     }
     recipeIndexesForPage(pageNumber:number) {
        if(pageNumber == 1) return [0,1,2,3,4,5,6,7];
        if(pageNumber == 2) return [8,9,10,11,12,13,14,15];
        if(pageNumber == 3) return [16,17,18,19,20,21,22,23];
        if(pageNumber == 4) return [24,25,26,27,28,29,30,31];
     }
     showDescription(evt:any,id:number) {
         if(evt) { evt.stopPropagation(); evt.preventDefault(); }
        let descEl:HTMLElement|null = document.querySelector('.descP[data-id="'+id+'"]');
        if(descEl) descEl.style.display = 'block';
        let descTextEl:HTMLElement|null = document.querySelector('.descP[data-id="'+id+'"] textarea');
        if(descTextEl) descTextEl.focus();
     }
     addRecipe(recipeIndex:number) {
        this.currentRecipeIndex = recipeIndex;
        this.openStage(4);
        //get recipes
        Axios.get(`${API_BASE_URL}/recipes/shared?columns=id,name,type,dsp_name,modified,account`,Globals.axiosConf)
        .then(({data})=>{
           this.recipes = data.map((rec:any)=>new Recipe(rec));
        }).catch(e=>{ console.error(e); });
     }
     replaceRecipeAtIndex(recipeIndex:number,newRecipe:Recipe) {
        Axios.get(`${API_BASE_URL}/recipes/${newRecipe.id}`,Globals.axiosConf)
        .then(({data})=>{
           this.currentRecipeIndex = -1;
           data.sequences = JSON.stringify(data.sequences);
           this.currentMenu.recipes[this.currentCategoryIndex].recipes[recipeIndex] = data;
           this.updateMenu({recipes:this.currentMenu.recipes});
           this.openStage(3);
        }).catch(e=>{ });
     }
     displayNameClicked(recipeIndex:number) {
         if (this.isRecipeEmpty(recipeIndex)) {
            this.addRecipe(recipeIndex);
         }
     }
     isRecipeEmpty(recipeIndex:number) {
         return this.currentMenu.recipes[this.currentCategoryIndex].recipes[recipeIndex].dsp_name == 'Empty';
     }

     //computed
     get currentAccount() { return Globals.currentAccountId; }


     //lifecycle
     async created() {
        await Globals.getMe();
        this.currentAccountId = Globals.currentAccountId;
        //get recipes
        Axios.get(`${API_BASE_URL}/compiles/shared?columns=id,account,description,date,JSON_LENGTH(recipes) as recipeCount`,Globals.axiosConf)
        .then(({data})=>{
           this.menus = data.map((men:any)=>new Menu(men)).sort((a:Menu,b:Menu)=>new Date(a.date).getTime() < new Date(b.date).getTime() ? 1 : -1);
           this.filteredMenus = [...this.menus];
        }).catch(e=>{ console.error(e); });

        if(this.$route.params && this.$route.params.id) {
           let tmpMen = new Menu({id:this.$route.params.id})
           this.viewMenu(tmpMen)
           .then(()=>{
             this.openStage(2);
           }).catch(e=>{})
        }
     }
     mounted() {
        //@ts-ignore
        $('.atooltip').tooltip();

        //@ts-ignore
        $(document).on('blur','.textAreaFocus',function(e) {
           //@ts-ignore
           $(this).hide();
        })
     }

   }


import "../../public/css/rcu.css";
import Recipe from "../models/Recipe";
import {
   Options,
   Vue
} from 'vue-class-component';

@Options({
   props: {
      recipe: Recipe
   },
   emits: ['recipeUpdate'],
   watch: {
      recipe(val) {
         this.initStuff();
      }
   }
})
export default class RecipeEditor extends Vue {
   //props
   recipe!: Recipe;

   //data variables
   portions:Array<any> = [{name:'',sequences:[]},{name:'',sequences:[]},{name:'',sequences:[]}];
   parameters:any = {
      dialog: {
         key: 'dialog',
         title: 'Dialog',
         params: [
            {
               key:'text',
               title:'Text',
               hint: 'Text to be show as a pop message on the machine. Could be instructions, cross selling products, etc.',
               unit: ''
            }
         ]
      },
      start: {
         key: 'start',
         title: 'Start',
         params: [
            {
               key:'tmp',
               title:'Water Temperature',
               hint: 'Temperature set once for entire recipe.',
               unit: 'F'
            }
         ]
      },
      vc: {
         key: 'vc',
         title: 'Vacuum',
         params: [
            {
               key:'ps',
               title:'Vacuum Setpoint',
               hint: 'Reverse Pressure (negative) you want. Enter only the number (0-60)',
               unit: 'kPs'
            },
            {
               key:'tm',
               title:'Vacuum Hold Time',
               hint: "Water holding Time at the pressure specified above.",
               unit: 's'
            },
            {
               key:'ap',
               title:'ATM Pause',
               hint: 'Time to let the water sit with no pressure.',
               unit: 's'
            }

         ]
      },
      fr: {
         key: 'fr',
         title: 'Fill',
         params: [
            {
               key:'fwv',
               title:'Fill Value',
               hint: 'Volume of water to dispense straight down.',
               unit: 'mL'
            },
            {
               key:'rwv',
               title:'Rinse Value',
               hint: 'Volume of water to be dispensed through the Rinse port.',
               unit: 'mL'
            },
            {
               key:'ap',
               title:'ATM Pause',
               hint: 'ime to let the water sit with no pressure.',
               unit: 's'
            }

         ]
      },
      pg: {
         key: 'pg',
         title: 'Purge',
         params: [
            {
               key:'ps',
               title:'Pressure Setpoint',
               hint: 'Pressure you want to push the water out of the chamber. The Pressure Control check box must be checked to be effective.',
               unit: 'kPa'
            },
            {
               key:'tm',
               title:'Pressure Hold Time',
               hint: 'Maximum time to push the water out at the specified pressure.',
               unit: 's'
            },
            {
               key:'rwv',
               title:'Rinse',
               hint: 'Volume of water used to rinse while purging.',
               unit: 'mL'
            },
            {
               key:'manstop',
               title:'Manual Stop',
               hint: 'Enable a dialog to stop Purge manually.',
               unit: '',
               type:'checkbox'
            },
            {
               key:'contr',
               title:'Purge Control',
               hint: 'Enable Pressure Control and Purge Detection.',
               unit: '',
               type: 'checkbox'
            },
            {
               key:'det',
               title:'Purge Detection',
               hint: 'Detect if all water has been purged. Require Pressure Control enabled.',
               unit: '',
               type:'checkbox'
            },
            {
               key:'dl',
               title:'Delay',
               hint: 'Time delay to start rinse on purge',
               unit: 's'
            }

         ]
      }
   };

   //methods
   removeStep(seqIndex:number) {
      if(!window.confirm("Are you sure you wish to remove this step?")) return;

      for(let i = 0; i < 3; i++) {
         this.portions[i].sequences.splice(seqIndex,1);
      }
      this.updateSequences();
   }
   updateSequences() {
      this.recipe.sequences = { portions: this.portions };
      this.$emit('recipeUpdate',this.recipe);
   }
   updatePortionValue(portionIndex:number,seqIndex:number,paramKey:string,val:string) {
      this.portions[portionIndex].sequences[seqIndex].values[paramKey] = val;
      this.updateSequences();
   }
   addFillStep(seqIndex:number) {
      for(let i = 0; i < 3; i++) {
         this.portions[i].sequences.splice(seqIndex,0,{type:'fr',values:{ fwv:'',rwv:'',ap:''}});
      }
   }
   addVacStep(seqIndex:number) {
      for(let i = 0; i < 3; i++) {
         this.portions[i].sequences.splice(seqIndex,0,{type:'vc',values:{ps:'',tm:'',ap:''}});
      }
   }
   addPurgeStep(seqIndex:number) {
      for(let i = 0; i < 3; i++) {
         this.portions[i].sequences.splice(seqIndex,0,{type:'pg',values:{det:1,contr:1,manstop:0,dl:'',tm:'',ps:''}});
      }
   }
   addDialogStep(seqIndex:number) {
      for(let i = 0; i < 3; i++) {
         this.portions[i].sequences.splice(seqIndex,0,{type:'dialog',values:{text:''}});
      }
   }
   addStartStep() {
      for(let i = 0; i < 3; i++) {
         this.portions[i].sequences.splice(0,0,{type:'start',values:{tmp:''}});
      }
   }
   initStuff() {
      if(!this.recipe.sequences.portions) this.recipe.sequences.portions = [];
      for(let a =0; a < 3; a++) {
         if(!this.recipe.sequences.portions[a]) this.recipe.sequences.portions[a] = {name:'',sequences:[]};
         for(let b =0; b < this.recipe.sequences.portions[0].sequences.length; b++) {
            if(a > 0 && !this.recipe.sequences.portions[a].sequences[b]) this.recipe.sequences.portions[a].sequences[b] = {...this.recipe.sequences.portions[0].sequences[b],values:{}}
         }
      }


      if(this.recipe && this.recipe.sequences && this.recipe.sequences.portions) {
         this.recipe.sequences.portions.forEach((portion:any,portionIndex:number)=>{
            this.portions[portionIndex] = portion;
         })
         this.portions.forEach((portion:any)=>{
            portion.sequences.forEach((sequence:any)=>{
               if(!sequence.values) sequence.values = {};
               if(this.parameters[sequence.type]) {
                  this.parameters[sequence.type].params.forEach((param:any)=>{
                     if(!sequence.values[param.key]) sequence.values[param.key] = '';
                  })
               }
            })
         })
      }
   }


   //computed


   //lifecycle
   created() {
      this.initStuff();
   }

}


   import { Options, Vue } from 'vue-class-component';
   import Recipe from '../models/Recipe';
   import {Account} from '../Types';
   import Axios from 'axios';
   import { API_BASE_URL } from '../environment';
   import Globals from '../Globals';

   @Options({
      props:{
         recipes:Array
      },
      watch: {
         recipes(val) {
            this.setAccountsFromRecipes();
            this.filterRecipesBySearchValue('');
         }
      },
      emits: ['recipeSelected']
})
   export default class RecipeImportModal extends Vue {
       //props
       recipes!:Array<Recipe>;

        //data variables
        searchTerm:string = "";
        filterAccount:number = 0;
        filteredRecipes:Array<Recipe> = [];
        accounts:Array<Account> = [];

        //methods
        filterRecipesBySearchValue(searchedValue:string,recipes:Array<Recipe>|null = null) {
          if (searchedValue == "") return this.filteredRecipes = [...(recipes || this.recipes)];
          this.filteredRecipes = (recipes || this.recipes).filter((recipe:Recipe) =>recipe.dsp_name.toLowerCase().includes(searchedValue.toLowerCase())).slice(0,50);
        }
        filterByAccount(acctId:number,recipes:Array<Recipe>|null = null) {
           if(acctId == 0) return this.filteredRecipes = [...(recipes || this.recipes)];
           this.filteredRecipes = (recipes || this.recipes).filter((rec:Recipe)=>rec.account == acctId);
        }
        runFilter() {
           this.filterByAccount(this.filterAccount);
           this.filterRecipesBySearchValue(this.searchTerm,this.filteredRecipes);
        }
        setAccountsFromRecipes() {
           let accountIds:Array<number> = this.recipes.map((rec:Recipe)=>rec.account);
           Axios.get(`${API_BASE_URL}/accounts?columns=id,name&id=${accountIds.join(',,')}`,Globals.axiosConf)
           .then(({data})=>{
             this.accounts = data.map((dat:any)=>new Account(dat));
          }).catch(e=>{})
        }
        accountNameForId(acctId:number) {
           let account = this.accounts.find((acct:Account)=>acct.id == acctId);
           return account ? account.name : '( unknown )';
        }

        //computed

        //lifecycle
        created() {
           this.filterRecipesBySearchValue('');
        }


   }
